import {
  Alert,
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Mysocial360Logo } from '../utils/logos';
import { validateInput } from '../utils/form_validator';
import API from '../utils/api';
import Cookies from 'js-cookie';
import { useAuthContext } from '../hooks/useAuthContext';
import { AUTH_ACTIONS } from '../context/reducers/authReducer';
import toast from 'react-hot-toast';

const Register = () => {
  const { dispatch } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userCredentials, setUserCredentials] = useState({
    name: { value: '', touched: false, hasError: true, error: '' },
    email: { value: '', touched: false, hasError: true, error: '' },
    password: { value: '', touched: false, hasError: true, error: '' },
    confirmPassword: { value: '', touched: false, hasError: true, error: '' },
    isFormValid: false,
  });

  const handleGoogleAuth = () => {
    window.open(`${API.BASE_URL}/mysocial360/api/auth/google`, '_self');
  };

  const handleFacebookAuth = () => {
    window.open(`${API.BASE_URL}/mysocial360/api/auth/facebook`, '_self');
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const { hasError, error } = validateInput(name, value);
    let isFormValid = true;

    for (const key in userCredentials) {
      const item = userCredentials[key];
      if (key === name && hasError) {
        isFormValid = false;
        break;
      } else if (key !== name && item && item.hasError) {
        isFormValid = false;
        break;
      }
    }

    setUserCredentials({
      ...userCredentials,
      [name]: {
        ...userCredentials[name],
        value: value,
        hasError,
        error,
        touched: false,
      },
      isFormValid,
    });
  };
  const onFocusOut = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const { hasError, error } = validateInput(
      name,
      value,
      userCredentials.password.value
    );
    let isFormValid = true;

    for (const key in userCredentials) {
      const item = userCredentials[key];
      if (key === name && hasError) {
        isFormValid = false;
        break;
      } else if (key !== name && item && item.hasError) {
        isFormValid = false;
        break;
      }
    }

    setUserCredentials({
      ...userCredentials,
      [name]: {
        ...userCredentials[name],
        value: value,
        hasError,
        error,
        touched: true,
      },
      isFormValid,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userCredentials.isFormValid)
      return toast.error('Please Fill all fields!');

    const body = {
      name: userCredentials.name.value,
      email: userCredentials.email.value,
      password: userCredentials.password.value,
    };

    try {
      const res = await API.Post(`/auth/signup`, body);
      const { access_token, user } = res.data;
      Cookies.set('user', JSON.stringify(user));
      Cookies.set('access_token', access_token);
      dispatch({
        type: AUTH_ACTIONS.LOGIN_SUCCESS,
        payload: { token: access_token, user },
      });

      setTimeout(
        () => toast.success(`Welcome ${user?.name.split(' ')[0]}`),
        500
      );
    } catch (error) {
      if (error.response.status === 409) {
        const msg = error.data.message;
        toast.error(msg);
      }
      if (error.response.status === 500) {
        toast.error('Something went wrong');
      }
      if (error.response.status === 400) {
        toast.error('Something went wrong');
      }
    }
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          overflowY: 'scroll',
          background: '#e0dfdf',
        }}
      >
        <Stack
          alignItems="center"
          sx={{
            background: '#fff',
            height: 'auto',
            width: '340px',
            borderRadius: '20px',
            padding: '20px',
            marginTop: open ? '90px' : '0',
            marginBottom: open ? '30px' : '0',
          }}
        >
          <Avatar src={Mysocial360Logo} sx={{ width: 100, height: 100 }} />
          <Typography
            variant="h5"
            sx={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.87)' }}
          >
            Mysocial360 Register
          </Typography>

          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Stack
              spacing={2}
              justifyContent="center"
              alignItems="center"
              mt={2}
              mb={3}
            >
              {open && (
                <Collapse in={open}>
                  <Alert
                    severity="error"
                    onClose={() => {
                      setOpen(false);
                    }}
                  >
                    Form error — Please Fill all fields!
                  </Alert>
                </Collapse>
              )}
              <TextField
                fullWidth
                size="small"
                label="Full Name"
                type="text"
                name="name"
                value={userCredentials.name.value}
                onChange={handleChange}
                onBlur={onFocusOut}
                error={
                  userCredentials.name.touched && userCredentials.name.hasError
                    ? true
                    : false
                }
                helperText={
                  userCredentials.name.touched && userCredentials.name.hasError
                    ? userCredentials.name.error
                    : ''
                }
              />
              <TextField
                fullWidth
                size="small"
                label="Email"
                type="email"
                name="email"
                value={userCredentials.email.value}
                onChange={handleChange}
                onBlur={onFocusOut}
                error={
                  userCredentials.email.touched &&
                  userCredentials.email.hasError
                    ? true
                    : false
                }
                helperText={
                  userCredentials.email.touched &&
                  userCredentials.email.hasError
                    ? userCredentials.email.error
                    : ''
                }
              />
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={
                  userCredentials.password.touched &&
                  userCredentials.password.hasError
                    ? true
                    : false
                }
              >
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  id="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={userCredentials.password.value}
                  onChange={handleChange}
                  onBlur={onFocusOut}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {userCredentials.password.touched &&
                  userCredentials.password.hasError
                    ? userCredentials.password.error
                    : ''}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={
                  userCredentials.confirmPassword.touched &&
                  userCredentials.confirmPassword.hasError
                    ? true
                    : false
                }
              >
                <InputLabel htmlFor="confirmPassword">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  id="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  value={userCredentials.confirmPassword.value}
                  onChange={handleChange}
                  onBlur={onFocusOut}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {userCredentials.confirmPassword.touched &&
                  userCredentials.confirmPassword.hasError
                    ? userCredentials.confirmPassword.error
                    : ''}
                </FormHelperText>
              </FormControl>

              <Button type="submit" variant="contained" fullWidth color="info">
                Register
              </Button>
            </Stack>
          </form>
          <Divider
            sx={{
              width: '100%',
              fontSize: '14px',
            }}
          >
            Or Register With
          </Divider>
          <Stack
            direction="row"
            justifyContent="center"
            spacing={1}
            mt={3}
            sx={{ width: '100%' }}
          >
            <Button
              variant="contained"
              color="error"
              startIcon={<GoogleIcon />}
              size="small"
              onClick={handleGoogleAuth}
            >
              Google
            </Button>
            <Button
              variant="contained"
              startIcon={<FacebookIcon />}
              size="small"
              onClick={handleFacebookAuth}
            >
              Facebook
            </Button>
          </Stack>

          <Stack
            direction="row"
            pt={3}
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Typography>Already have an account ?</Typography>
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <Button>Login</Button>
            </Link>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default Register;
