import axios from 'axios';

const base = 'https://app.mysocial360.com';
//'https://app.mysocial360.com' 'http://localhost:4000'

const api = axios.create({
  baseURL: `${base}/mysocial360/api`,
});

const headers = (token) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
};


const API = {
  BASE_URL: base
};

API.Get = (route, token) => api.get(route, headers(token));
API.Post = (route, body = {}, token) => api.post(route, body, headers(token));
API.Delete = (route, token) => api.delete(route, headers(token));
API.Patch = (route, body = {}, token) => api.patch(route, body, headers(token));

export default API;
