import {
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ReactGPicker from 'react-gcolor-picker';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import API from '../utils/api';
import toast from 'react-hot-toast';

const ColorPaletteComponent = ({ token }) => {
  const swal = withReactContent(Swal);
  const [alignment, setAlignment] = useState('--calendar-background-color');
  const [elementsValues, setElementsValues] = useState({
    '--calendar-background-color': '',
    '--calendar-day-top-color': '',
    '--calendar-day-frame-color': '',
    '--calendar-today-color': '',
    '--calendar-buttons-color': '',
    '--calendar-buttons-font-color': '',
    '--calendar-day-font-color': '',
    '--calendar-date-font-color': '',
  });
  const [backgroundImage, setBackgroundImage] = useState('');

  const handleToggleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const onColorChange = (value) => {
    if (alignment === '--calendar-background-color') {
      document.documentElement.style.setProperty(
        '--calendar-background-url',
        ''
      );
    }
    document.documentElement.style.setProperty(alignment, value);
    setElementsValues({ ...elementsValues, [alignment]: value });
  };

  const handleBackgroundImageChange = (e) => setBackgroundImage(e.target.value);

  const handleSetBackgroundImage = () => {
    document.documentElement.style.setProperty(
      '--calendar-background-color',
      ''
    );
    setElementsValues({ ...elementsValues, '--calendar-background-color': '' });
    document.documentElement.style.setProperty(
      '--calendar-background-url',
      `url(${backgroundImage})`
    );
  };
  const handleClearBackgroundTextField = () => setBackgroundImage('');

  const handleSaveSettings = () => {
    try {
      if (
        elementsValues['--calendar-background-color'] === '' &&
        elementsValues['--calendar-day-top-color'] === '' &&
        elementsValues['--calendar-day-frame-color'] === '' &&
        elementsValues['--calendar-today-color'] === '' &&
        elementsValues['--calendar-buttons-color'] === '' &&
        elementsValues['--calendar-buttons-font-color'] === '' &&
        elementsValues['--calendar-day-font-color'] === '' &&
        elementsValues['--calendar-date-font-color'] === '' &&
        backgroundImage === ''
      )
        return toast.error('No changes was done to save');

      const body = {
        backgroundColor: elementsValues['--calendar-background-color'],
        dayTopColor: elementsValues['--calendar-day-top-color'],
        dayFrameColor: elementsValues['--calendar-day-frame-color'],
        todayColor: elementsValues['--calendar-today-color'],
        buttonsColor: elementsValues['--calendar-buttons-color'],
        buttonsFontColor: elementsValues['--calendar-buttons-font-color'],
        dayFontColor: elementsValues['--calendar-day-font-color'],
        dateFontColor: elementsValues['--calendar-date-font-color'],
        backgroundImage,
      };

      const res = API.Patch('/calendar/', body, token);

      toast.promise(res, {
        loading: 'Saving preferences...',
        success: ({ data }) => {
          swal.close();
          return 'Settings saved successfully';
        },
        error: ({ response }) => {
          return 'Something went wrong';
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleResetToDefault = () => {
    const res = API.Patch('/calendar/reset', {}, token);

    toast.promise(res, {
      loading: 'Resetting to Default...',
      success: ({ data }) => {
        resetCss();
        swal.close();
        return data.message;
      },
      error: ({ response }) => {
        console.log(response);
        if (response.status === 400) return 'You have no settings to Reset';
        return 'Something went wrong';
      },
    });
  };

  const resetCss = () => {
    document.documentElement.style.setProperty(
      '--calendar-background-url',
      `url('')`
    );
    document.documentElement.style.setProperty(
      '--calendar-background-color',
      ''
    );
    document.documentElement.style.setProperty(
      '--calendar-day-top-color',
      '#e2b7db'
    );
    document.documentElement.style.setProperty(
      '--calendar-day-frame-color',
      'rgba(197, 196, 207, 0.15)'
    );
    document.documentElement.style.setProperty(
      '--calendar-today-color',
      'rgba(21, 7, 150, 0.15)'
    );
    document.documentElement.style.setProperty(
      '--calendar-buttons-color',
      '#2c3e50'
    );
    document.documentElement.style.setProperty(
      '--calendar-buttons-font-color',
      '#fff'
    );
    document.documentElement.style.setProperty('--calendar-day-font-color', '');
    document.documentElement.style.setProperty(
      '--calendar-date-font-color',
      '#fff'
    );
  };

  return (
    <div>
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleToggleChange}
          aria-label="Platform"
        >
          <ToggleButton value="--calendar-background-color">
            Background
          </ToggleButton>
          <ToggleButton value="--calendar-day-top-color">Date Top</ToggleButton>
          <ToggleButton value="--calendar-day-frame-color">
            Date Frame
          </ToggleButton>
          <ToggleButton value="--calendar-today-color">Today</ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleToggleChange}
          aria-label="Platform"
        >
          <ToggleButton value="--calendar-buttons-color">Buttons</ToggleButton>
          <ToggleButton value="--calendar-buttons-font-color">
            Buttons Font
          </ToggleButton>
          <ToggleButton value="--calendar-day-font-color">
            Day Font
          </ToggleButton>
          <ToggleButton value="--calendar-date-font-color">
            Date Font
          </ToggleButton>
        </ToggleButtonGroup>

        <Button onClick={handleResetToDefault}>Reset To Default Colors</Button>
        {alignment === '--calendar-background-color' && (
          <>
            <Divider />
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              sx={{ width: '100%' }}
              spacing={2}
            >
              <TextField
                fullWidth
                value={backgroundImage}
                label="Enter Background Image Url"
                name="--calendar-background-url"
                onChange={handleBackgroundImageChange}
              />
              <Button variant="contained" onClick={handleSetBackgroundImage}>
                Set
              </Button>
              <IconButton size="small" onClick={handleClearBackgroundTextField}>
                <ChangeCircleIcon />
              </IconButton>
            </Stack>
            <Divider
              sx={{
                width: '100%',
                fontSize: '14px',
              }}
            >
              {' '}
              OR{' '}
            </Divider>
            <Typography>Use the Background Color Option Below</Typography>
          </>
        )}
        <ReactGPicker value="red" gradient solid onChange={onColorChange} />
        <Button variant="contained" fullWidth onClick={handleSaveSettings}>
          Save Settings
        </Button>
      </Stack>
    </div>
  );
};

export default ColorPaletteComponent;
