import { Card, Stack, Typography } from '@mui/material'
import React from 'react'
import ImageIcon from '@mui/icons-material/Image';
import { FacebookLogo } from '../../utils/logos';

function BlocksInnerCircleContest({ drag }) {
    return (
        <div>
            <Card
                className="drag-item"
                draggable="true"
                onDragStart={drag}
                data-node="image"
                sx={{ marginBottom: '13px' }}
            >
                <Stack direction="row" alignItems="center" spacing={2}>
                    <ImageIcon
                        sx={{
                            width: 25,
                            height: 25,
                            padding: '10px',
                            color: '#6e6d6d',
                        }}
                    />
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#6e6d6d',
                            fontSize: '1rem',
                            fontWeight: '400',
                        }}
                    >
                        Image
                    </Typography>
                </Stack>
            </Card>
            <Card
                className="drag-item"
                draggable="true"
                onDragStart={drag}
                data-node="fbpost"
                sx={{ marginBottom: '13px' }}
            >
                <Stack direction="row" alignItems="center" spacing={2}>
                    <img
                        src={FacebookLogo}
                        style={{
                            width: 25,
                            height: 25,
                            padding: '10px',
                            color: '#6e6d6d',
                        }}
                        alt="facebookLogo"
                    />
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#6e6d6d',
                            fontSize: '1rem',
                            fontWeight: '400',
                        }}
                    >
                        Post
                    </Typography>
                </Stack>
            </Card>
        </div>
    )
}

export default BlocksInnerCircleContest