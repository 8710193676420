import {
  Modal,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import swal from 'sweetalert';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function NewChallengeModal({
  open,
  isEdit,
  handleClose,
  challenge,
  handleCreateChallenge,
  handleEditChallenge,
  handleChallengeChange,
  handleChallengeDateChange,
}) {
  const [loading, setLoading] = useState(false);

  const onCreateChallenge = async (e) => {
    e.preventDefault();
    setLoading(true);
    await handleCreateChallenge();
    setLoading(false);
  };

  const onEditChallenge = async (e) => {
    e.preventDefault();
    setLoading(true);
    await handleEditChallenge();
    setLoading(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={isEdit ? onEditChallenge : onCreateChallenge}>
            <Stack spacing={3}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {isEdit ? 'Edit' : 'Create'} Your Challenge
              </Typography>
              <TextField
                fullWidth
                autoFocus
                label="Challenge Name"
                name="name"
                value={challenge?.name}
                onChange={handleChallengeChange}
              />
              <TextField
                fullWidth
                //autoFocus
                InputProps={{ inputProps: { min: 1 } }}
                label="Days for Challenge"
                name="days"
                value={challenge?.days}
                type="number"
                onChange={handleChallengeChange}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={challenge?.start}
                  onChange={(newValue) => {
                    handleChallengeDateChange(newValue);
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
              <Stack direction="row" justifyContent="flex-end">
                <Button disabled={loading} variant="contained" type="submit">
                  {loading
                    ? 'LOADING...'
                    : isEdit
                    ? 'Edit Challenge'
                    : 'CREATE CHALLENGE'}
                </Button>
              </Stack>
            </Stack>{' '}
          </form>
        </Box>
      </Modal>
    </div>
  );
}

export default NewChallengeModal;
