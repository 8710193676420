import {
  BosLogo,
  ChatBosLogo,
  CliktrackerLogo,
  CopypasteLogo,
  CRMLogo,
  CursorLogo,
  EmojiLogo,
  FBHackerLogo,
  FBScraperLogo,
  FoeOrBroLogo,
  InnerCircleLogo,
  Mysocial360Logo,
  SplashLogo,
  StorieReelsLogo,
  FacebookLogo,
  MessageLogo,
  DayLogo,
  VaVickyLogo,
  PageLogo,
  GoogleLogo,
} from '../utils/logos';

export function getText() {
  const html = `
        <div>
          <div class="title-box" style="display:flex; align-items:center; font-weight:700;"><img src="images/logo.png" alt="" class="logo">   Send a Message</div>
          <div class="box">
            <p>Add Trigger</p>
            <input type="text" df-trigger/>
            <p>Add Message</p> 
            <textarea df-message></textarea>
          </div>
        </div>
        `;
  return {
    html: html, class: 'dragElement',
    id: 'text',

    data: { message: '' },
  };
}

export function getVaText() {
  const html = `
        <div>
          <div class="title-box" style="display:flex; align-items:center; font-weight:700;"><img src="images/logo.png" alt="" class="logo">   Send a Message</div>
          <div class="box">
           
            <p>Add Trigger</p>
            <input type="text" df-trigger  placeholder="Separate with ,"/>
            <p>Delay (Sec)</p>
            <input type="text" df-delay/>
            <p>Add Message</p> 
            <textarea df-message placeholder="Write your message here..."></textarea>
          </div>
        </div>
        `;
  return {
    html: html, class: 'dragElement',
    id: 'text',
    data: { message: '', trigger: '', delay: 3 },
  };
}

export function getImage() {
  const html = `
    <div>
      <div
        class="title-box"
        style="display:flex; align-items:center; font-weight:700;"
      >
        <img src="images/logo.png" alt="" class="logo" /> Send an Image
      </div>
      <div class="box">
        <p>Add Trigger</p>
        <input type="text" df-trigger/>
        <label for="drawflow-image-upload" class="image-upload">
          Add Image
          <input hidden type="file" name="df-image" id="drawflow-image-upload"/>
        </label>
      </div>
    </div>
  `;

  return {
    html: html, class: 'dragElement',
    id: 'image',
    data: {},
  };
}

export function getFBPost() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${FacebookLogo} width="30" height="30"/>&nbsp;
        FB Post
      </div>\n 
      <div class="box">
            <p>Add Post time</p>
            <input type="time" df-time /> 
            <p>Add Title</p> 
            <input type="text" placeholder="Title" df-title /> 
            <p>Add Image Url</p> 
            <input type="text" placeholder="Image Url" df-imgUrl /> 
            <p>Add Content</p> 
            <textarea placeholder="Content..." df-text></textarea>
          </div> 
    </div>
    `;
  return {
    html: html,
    id: 'fbpost',
    class: 'dragElement',
    data: { time: '', title: '', description: '', imgUrl: '' },
  };
}

export function getFBMessenger() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${MessageLogo} width="30" height="30"/>&nbsp;
        Send Message
      </div>\n 
      <div class="box">
            <p>Add Message</p> 
            <textarea df-message></textarea>
          </div> 
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'messenger',
    data: { message: '' },
  };
}

export function getDelay(defaultTime = 3) {
  const html = `
    <div>
    <div class="title-box" style="display:flex; align-items:center; font-weight:700;"> Delay </div>
    <div class="box">
      <p>Add your delay time</p>
      <input type="number" df-time>
    </div>
  </div>
        `;
  return {
    html: html, class: 'dragElement',
    id: 'delay',
    data: { time: defaultTime },
  };
}

export function getEmail() {
  const html = `
        <div>
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;"> Email </div>
            <div class="box">
                <p>Send Email</p>
            </div>
         </div>
        `;

  return {
    html: html, class: 'dragElement',
    id: 'email',
    data: {},
  };
}

export function getNotification() {
  const html = `
        <div>
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">Send Notification</div>
            <div class="box">
                <p>Send Notification</p>
            </div>
         </div>
        `;

  return {
    html: html, class: 'dragElement',
    id: 'notification',
    data: {},
  };
}

export function getChatbos() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${ChatBosLogo} width="30" height="30"/>&nbsp;
        Chatbos
      </div>\n  
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'chatbos',
    data: {},
  };
}

export function getVaVicky() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${VaVickyLogo} width="30" height="30"/>&nbsp;
        VA Vicky
      </div>\n<br/>  
      <div class="box">
        <p>Default Message</p>
        <input type="number" df-welcome/>
      </div>
      <div class="box">
        <p>Average Delay Between Message (sec)</p>
        <input type="number" df-delay min="1"/>
      </div>
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'vavicky',
    data: {
      welcome: "",
      delay: 3
    },
  };
}

export function getSocial360CRM() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${CRMLogo} width="30" height="30"/>&nbsp;
        CRM
      </div>\n  
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'crm',
    data: {},
  };
}

export function getMysocial360() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${Mysocial360Logo} width="30" height="30"/>&nbsp;
        Mysocial360
      </div>\n  
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'mysocial360',
    data: {},
  };
}

export function getFbscraper() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${FBScraperLogo} width="30" height="30"/>&nbsp;
        FB Scraper
      </div>\n  
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'fbscraper',
    data: {},
  };
}

export function getStorieReels() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${StorieReelsLogo} width="30" height="30"/>&nbsp;
        Storie Reels
      </div>\n  
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'storiereels',
    data: {},
  };
}

export function get360Emojis() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${EmojiLogo} width="30" height="30"/>&nbsp;
        360 Emojis
      </div>\n  
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: '360emojis',
    data: {},
  };
}

export function getCopyPaste360() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${CopypasteLogo} width="30" height="30"/>&nbsp;
        Copy Paste 360
      </div>\n  
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'copypaste360',
    data: {},
  };
}

export function getCliktracker() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${CliktrackerLogo} width="30" height="30"/>&nbsp;
        Cliktracker
      </div>\n  
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'cliktracker',
    data: {},
  };
}

export function getFoeOrBro() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${FoeOrBroLogo} width="30" height="30"/>&nbsp;
        Foe or Bro
      </div>\n  
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'foeorbro',
    data: {},
  };
}

export function getInnerCircleDeets() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${InnerCircleLogo} width="30" height="30"/>&nbsp;
        Inner Circle Deets
      </div>\n  
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'innercircledeets',
    data: {},
  };
}

export function getFBGroupHacker() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${FBHackerLogo} width="30" height="30"/>&nbsp;
        FB Group Master
      </div>\n  
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'fbgrouphacker',
    data: {},
  };
}


export function getGoogleLink() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${GoogleLogo} width="30" height="30"/>&nbsp;
        Google Link
      </div>\n 
      <div class="box">
            <p>Add Link</p> 
            <input df-link></input>
          </div> 
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'google-link',
    data: { link: '' },
  };
}

export function getFacebookLink() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${FacebookLogo} width="30" height="30"/>&nbsp;
        Facebook Link
      </div>\n 
      <div class="box">
            <p>Add Link</p> 
            <input df-link></input>
          </div> 
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'facebook-link',
    data: { link: '' },
  };
}

export function getClickTrackerLink() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${PageLogo} width="30" height="30"/>&nbsp;
        Page
      </div>\n 
      <div class="box">
          <input df-name placeholder="Page Name"></input>
          <p>Link</p> 
          <input df-link placeholder="URL"></input>
      </div> 
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'facebook-link',
    data: { link: '' },
  };
}


export function getClickTrackerLinkCustom(imageUrl, name="Page") {
  
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${CliktrackerLogo} width="30" height="30"/>&nbsp;
        ${name}
      </div>\n 
      <div class="box">
          <img src="${imageUrl}" style="width:100%" />
          <input df-name placeholder="Page Name"></input>
          <p>Link</p> 
          <input df-link placeholder="URL"></input>
      </div> 
    </div>
    `;
  return {
    html: html, class: 'dragElement',
    id: 'facebook-link',
    data: { link: '' },
  };
}