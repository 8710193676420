import React, { useCallback, useEffect, useState, useContext } from 'react';
import './css/drawflow.css';
import './css/beautiful.css';
import './css/challengesCss.css';
import debounce from 'lodash.debounce';
import Drawflow from 'drawflow';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SearchIcon from '@mui/icons-material/Search';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
// import NotesIcon from '@mui/icons-material/Notes';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import MailIcon from '@mui/icons-material/Mail';
// import ImageIcon from '@mui/icons-material/Image';
// import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import BaseStyleComponent from '../../components/BaseStyleComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { FacebookLogo, MessageLogo } from '../../utils/logos';
import { apps } from '../../constants/apps_data';
import swal from 'sweetalert';
import { DrawflowContext } from '../../context/drawflowContext';
import { DRAWFLOW_ACTIONS } from '../../context/reducers/drawflowReducer';
import API from '../../utils/api';
import { useAuthContext } from '../../hooks/useAuthContext';
import toast from 'react-hot-toast';
import * as Blocks from '../../constants/blocks';
import { zoom_check } from '../../constants/zoom_check';
import BlocksInnerCircleContest from './BlocksInnerCircleContest';
import BlocksVaVicky from './BlocksVaVicky';
import BlocksCliktracker from './BlocksCliktracker';

export const ContextSearch = React.createContext("");
export const ContextWireFlowIcons = React.createContext([]);
export const ContextBOSIcons = React.createContext([]);

const DrawPage = () => {


  const [state, dispatch] = useContext(DrawflowContext);
  const { token } = useAuthContext();
  const [search, setSearch] = useState("");
  const [wireflowIcons, setWireflowIcons] = useState([]);
  const [bosIcons, setBosIcons] = useState([]);
  const [editorState, setEditorState] = useState(new Drawflow());
  const [mobileItemSelected, setMobileItemSelected] = useState('');
  const [mobileLastMove, setMobileLastMove] = useState(null);
  const [transform, setTransform] = useState('');
  const [zoomLevel, setZoomLevel] = useState();
  const [dataToImport, setDataToImport] = useState({});
  const [imageToBeDeleted, setImageToBeDeleted] = useState({
    id: '',
    image: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [lockFlow, setLockFlow] = useState(true);
  const navigate = useNavigate();

  const {
    state: { platform, id },
  } = useLocation();

  const [open, setOpen] = useState(false);

  const handleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const challengeCssChange = () => {
    document.documentElement.style.setProperty('--top-value', '100% - 10px');
    document.documentElement.style.setProperty('--left-value', '50%');
    document.documentElement.style.setProperty(
      '--elem-inputs-left-value',
      '50%'
    );
    document.documentElement.style.setProperty(
      '--elem-outputs-left-value',
      '50%'
    );
    document.documentElement.style.setProperty(
      '--elem-inputs-top-value',
      ' 0% - 10px'
    );
    document.documentElement.style.setProperty(
      '--elem-outputs-top-value',
      '100% - 10px'
    );
  };

  const flowCssChange = () => {
    document.documentElement.style.setProperty('--top-value', '45%');
    document.documentElement.style.setProperty('--left-value', '95%');
    document.documentElement.style.setProperty(
      '--elem-inputs-left-value',
      '0%'
    );
    document.documentElement.style.setProperty(
      '--elem-outputs-left-value',
      '100%'
    );
    document.documentElement.style.setProperty(
      '--elem-inputs-top-value',
      '45%'
    );
    document.documentElement.style.setProperty(
      '--elem-outputs-top-value',
      '45%'
    );
  };

  useEffect(() => {
    async function run() {
      try {

        const res = await API.Get('/images/icons/wireflow', token);
        setWireflowIcons(res.data);

        const res2 = await API.Get('/images/icons/bos', token);
        setBosIcons(res2?.data || []);

      } catch (e) {
        console.log(e.message);
      }
    }
    run()
  }, []);

  useEffect(() => {
    dispatch({
      type: DRAWFLOW_ACTIONS.IS_FLOW,
      field: 'isFlow',
      payload: true,
    });
    (async () => {
      if (platform === 'fb_hacker_challenge') {
        challengeCssChange();
        //Get Challenge
        const challenge = await fetchChallenge(id);
        setDataToImport(challenge);
        setTimeout(async () => {
          setIsLoading(false);
        }, 1000);
      } else {
        flowCssChange();
        const flow = await fetchFlow(id);
        setDataToImport(flow);
        setTimeout(async () => {
          setIsLoading(false);
        }, 1000);
      }
    })();
  }, []);

  const fetchChallenge = async (flowID) => {
    try {
      const res = await API.Get(`/challenge/getChallenge/${flowID}`, token);

      setZoomLevel(res.data.zoom['$numberDecimal'].toLocaleString());
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFlow = async (flowID) => {
    try {
      const res = await API.Get(`/flows/flow/${flowID}`, token);
      setZoomLevel(res.data.zoom['$numberDecimal'].toLocaleString());
      return res.data.flow;
    } catch (error) {
      console.log(error);
    }
  };

  const measuredRef = useCallback(
    async (node) => {
      if (node && dataToImport) {
        const editor = new Drawflow(node);
        editor.reroute = true;
        editor.zoom_min = 0.005;
        editor.start();
        editor.import(dataToImport);
        if (zoomLevel) {
          const zoom_level = await zoom_check(zoomLevel);
          for (let i = 0; i < zoom_level; i++) {
            if (parseInt(zoomLevel) > 0) {
              editor.zoom_in();
            } else {
              editor.zoom_out();
            }
          }
        }
        setEditorState(editor);
      }
    },
    [dataToImport]
  );

  const deleteImage = () => {
    const res = API.Delete(`/images/${imageToBeDeleted.image}`, token);
    toast.promise(res, {
      loading: 'Deleting Image',
      success: 'Image deleted',
      error: (error) => {
        return 'Error Deleting';
      },
    });
  };

  editorState.on('nodeCreated', async (id) => {
    editorImageUploadListenerDebounceFn();
    await addFlow(editorState);
    editorStateDebounceFn(editorState);
  });

  editorState.on('nodeRemoved', async (id) => {
    if (imageToBeDeleted.id === id) {
      deleteImage();
    }
    await addFlow(editorState);
    editorStateDebounceFn(editorState);
  });

  editorState.on('connectionStart', async (id) => {
    //toast(`connection started`);
  });
  editorState.on('connectionCancel', async (id) => {
    //toast(`connection cancelled:${id} `);
  });

  editorState.on('connectionCreated', async (connection) => {
    await addFlow(editorState);
    editorStateDebounceFn(editorState);
  });

  editorState.on('connectionRemoved', async (connection) => {
    await addFlow(editorState);
    editorStateDebounceFn(editorState);
  });

  editorState.on('nodeMoved', async (id) => {
    await addFlow(editorState);
    editorStateDebounceFn(editorState);
  });

  editorState.on('translate', async (position) => {
    await addFlow(editorState);
    editorStateDebounceFn(editorState);
  });
  editorState.on('nodeSelected', async (id) => { });

  editorState.on('contextmenu', async (e) => {
    const id = e.target.closest('.drawflow-node').id.slice(5);
    const node = editorState.getNodeFromId(id);
    const nodeData = node.data;

    if (nodeData['df-image']) {
      setImageToBeDeleted({
        id,
        image: nodeData['df-image'].replace(
          `${API.BASE_URL}/mysocial360/api/images/`,
          ''
        ),
      });
    }

    const nodeContainer = document.querySelector(`#node-1`);
    let elementFound = false;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          for (let i = 0; i < mutation.addedNodes.length; i++) {
            const deleteBtn = document.querySelectorAll('.drawflow-delete');

            if (deleteBtn.length > 0) {
              //stop loop
              i = mutation.addedNodes.length;
              elementFound = true;
              observer.disconnect();
            }
          }
        }
      });
      if (elementFound) {
        run();
      }
    });

    observer.observe(nodeContainer, { childList: true, subtree: true });
    const run = () => {
      const deleteBtn = document.querySelector('.drawflow-delete');
      deleteBtn.remove();
    };
  });

  editorState.on('addReroute', async (id) => {
    await addFlow(editorState);
    editorStateDebounceFn(editorState);
  });

  editorState.on('removeReroute', async (id) => {
    await addFlow(editorState);
    editorStateDebounceFn(editorState);
  });

  editorState.on('rerouteMoved', async (id) => {
    await addFlow(editorState);
    editorStateDebounceFn(editorState);
  });

  editorState.on('nodeDataChanged', async (id) => {
    await addFlow(editorState);
    editorStateDebounceFn(editorState);
  });

  editorState.on('zoom', async (zoom_level) => {
    await addFlow(editorState);
    editorStateDebounceFn(editorState);
  });

  const positionMobile = (ev) => {
    setMobileLastMove(ev);
  };

  const allowDrop = (ev) => {
    ev.preventDefault();
  };

  const drag = (ev) => {
    if (ev.type === 'touchstart') {
      setMobileItemSelected(
        ev.target.closest('.drag-item').getAttribute('data-node')
      );
    } else {
      ev.dataTransfer.setData('node', ev.target.getAttribute('data-node'));
    }
  };

  const editorStateDebounceFn = useCallback(
    debounce(async (editor) => {
      dispatch({
        type: DRAWFLOW_ACTIONS.SAVING,
        payload: true,
      });
      await saveFlow(editor);
      setTimeout(() => {
        dispatch({
          type: DRAWFLOW_ACTIONS.SAVING,
          payload: false,
        });
      }, 1500);
    }, 500),
    []
  );

  const editorImageUploadListenerDebounceFn = useCallback(
    debounce(() => {
      imageUploadListener(editorState);
    }, 500),
    [editorState]
  );

  const saveFlow = async (editor) => {
    try {
      const body = {
        id: id,
        flow: editor.drawflow,
        data: editor.drawflow,
        days: editor.drawflow.drawflow.Home.data.length - 1,
        zoom_level: editor.zoom,
      };

      const response = await API.Patch(
        platform === 'fb_hacker_challenge' ? `/challenge/${id}` : '/flows/saveFlow',
        body,
        token
      );
    } catch (error) {
      if (error.response.status === 500)
        return swal({ title: 'Something went wrong', icon: 'error' });
    }
  };

  const addFlow = () => {
    dispatch({
      type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
      field: 'flow',
      payload: { name: 'drawflow', value: editorState.drawflow.drawflow },
    });
  };

  const moveDebounceFn = useCallback(
    debounce((editor, item, move) => {
      console.log('we debouncing ... ', item);
      if (move)
        return addNodeToDrawFlow(
          editor,
          item,
          move.touches[0].clientX,
          move.touches[0].clientY
        );
    }, 100),
    []
  );

  const drop = async (ev) => {
    if (ev.type === 'touchend' && mobileLastMove != null) {
      const parentdrawflow = document
        .elementFromPoint(
          mobileLastMove.touches[0].clientX,
          mobileLastMove.touches[0].clientY
        )
        .closest('#drawflow');

      if (parentdrawflow != null && mobileLastMove != null) {
        await moveDebounceFn(editorState, mobileItemSelected, mobileLastMove);
      }
      setMobileItemSelected('');
    } else {
      ev.preventDefault();
      let data;
      if (ev.dataTransfer) {
        data = ev.dataTransfer.getData('node');
      }
      addNodeToDrawFlow(editorState, data, ev.clientX, ev.clientY);
    }
  };

  const addNodeToDrawFlow = (editor, name, pos_x, pos_y) => {
    if (editor.editor_mode === 'fixed') {
      return false;
    }

    pos_x =
      pos_x *
      (editor.precanvas.clientWidth /
        (editor.precanvas.clientWidth * editor.zoom)) -
      editor.precanvas.getBoundingClientRect().x *
      (editor.precanvas.clientWidth /
        (editor.precanvas.clientWidth * editor.zoom));
    pos_y =
      pos_y *
      (editor.precanvas.clientHeight /
        (editor.precanvas.clientHeight * editor.zoom)) -
      editor.precanvas.getBoundingClientRect().y *
      (editor.precanvas.clientHeight /
        (editor.precanvas.clientHeight * editor.zoom));

    switch (name) {
      case 'text':
        const text = Blocks.getText();
        editor.addNode(
          text.id,
          1,
          1,
          pos_x,
          pos_y,
          text.id,
          text.data,
          text.html
        );
        break;
      case 'vatext':
        const vaText = Blocks.getVaText();
        editor.addNode(
          vaText.id,
          1,
          1,
          pos_x,
          pos_y,
          vaText.id,
          vaText.data,
          vaText.html
        );
        break;
      case 'image':
        const image = Blocks.getImage();
        editor.addNode(
          image.id,
          1,
          1,
          pos_x,
          pos_y,
          image.class,
          image.data,
          image.html
        );
        break;
      case 'chatbos':
        const chatbos = Blocks.getChatbos();
        editor.addNode(
          chatbos.id,
          1,
          1,
          pos_x,
          pos_y,
          chatbos.id,
          chatbos.data,
          chatbos.html
        );
        break;
      case 'vavicky':
        const vavicky = Blocks.getVaVicky();
        editor.addNode(
          chatbos.id,
          1,
          1,
          pos_x,
          pos_y,
          vavicky.id,
          vavicky.data,
          vavicky.html
        );
        break;
      case 'crm':
        const crm = Blocks.getSocial360CRM();
        editor.addNode(crm.id, 1, 1, pos_x, pos_y, crm.id, crm.data, crm.html);
        break;
      case 'mysocial360':
        const mysocial360 = Blocks.getMysocial360();
        editor.addNode(
          mysocial360.id,
          1,
          1,
          pos_x,
          pos_y,
          mysocial360.id,
          mysocial360.data,
          mysocial360.html
        );
        break;
      case 'fb_scraper':
        const fbScraper = Blocks.getFbscraper();
        editor.addNode(
          fbScraper.id,
          1,
          1,
          pos_x,
          pos_y,
          fbScraper.id,
          fbScraper.data,
          fbScraper.html
        );
        break;
      case 'storieReels':
        const storiereels = Blocks.getStorieReels();
        editor.addNode(
          storiereels.id,
          1,
          1,
          pos_x,
          pos_y,
          storiereels.id,
          storiereels.data,
          storiereels.html
        );
        break;
      case '360_emojis':
        const emoji360 = Blocks.get360Emojis();
        editor.addNode(
          emoji360.id,
          1,
          1,
          pos_x,
          pos_y,
          emoji360.id,
          emoji360.data,
          emoji360.html
        );
        break;
      case 'copy_paste_360':
        const copy_paste_360 = Blocks.getCopyPaste360();
        editor.addNode(
          copy_paste_360.id,
          1,
          1,
          pos_x,
          pos_y,
          copy_paste_360.id,
          copy_paste_360.data,
          copy_paste_360.html
        );
        break;
      case 'clikTracker':
        const clikTracker = Blocks.getCliktracker();
        editor.addNode(
          clikTracker.id,
          1,
          1,
          pos_x,
          pos_y,
          clikTracker.id,
          clikTracker.data,
          clikTracker.html
        );
        break;


      case 'cliktracker-link':
        const clikTrackerLink = Blocks.getClickTrackerLink();
        editor.addNode(
          clikTrackerLink.id,
          1,
          1,
          pos_x,
          pos_y,
          clikTrackerLink.id,
          clikTrackerLink.data,
          clikTrackerLink.html
        );
        break;

      case 'foe_or_bro':
        const foeOrBro = Blocks.getFoeOrBro();
        editor.addNode(
          foeOrBro.id,
          1,
          1,
          pos_x,
          pos_y,
          foeOrBro.id,
          foeOrBro.data,
          foeOrBro.html
        );
        break;
      case 'innercircle_deets':
        const innerCircle = Blocks.getInnerCircleDeets();
        editor.addNode(
          innerCircle.id,
          1,
          1,
          pos_x,
          pos_y,
          innerCircle.id,
          innerCircle.data,
          innerCircle.html
        );
        break;
      case 'fb_hacker':
        const fbHacker = Blocks.getFBGroupHacker();
        editor.addNode(
          fbHacker.id,
          1,
          1,
          pos_x,
          pos_y,
          fbHacker.id,
          fbHacker.data,
          fbHacker.html
        );
        break;
      case 'delay':
        const delay = Blocks.getDelay();
        editor.addNode(
          delay.id,
          1,
          1,
          pos_x,
          pos_y,
          delay.id,
          delay.data,
          delay.html
        );
        break;
      case 'email':
        const email = Blocks.getEmail();
        editor.addNode(
          email.id,
          1,
          0,
          pos_x,
          pos_y,
          email.id,
          email.data,
          email.html
        );
        break;
      case 'notification':
        const notification = Blocks.getNotification();
        editor.addNode(
          notification.id,
          1,
          0,
          pos_x,
          pos_y,
          notification.id,
          notification.data,
          notification.html
        );
        break;
      case 'fbpost':
        const fbpost = Blocks.getFBPost();
        editor.addNode(
          fbpost.id,
          1,
          1,
          pos_x,
          pos_y,
          fbpost.class,
          fbpost.data,
          fbpost.html
        );
        break;
      case 'messenger':
        const messenger = Blocks.getFBMessenger();
        editor.addNode(
          messenger.id,
          1,
          1,
          pos_x,
          pos_y,
          messenger.id,
          messenger.data,
          messenger.html
        );
        break;
      case 'google-link':
        const gLink = Blocks.getGoogleLink();
        editor.addNode(
          gLink.id,
          1,
          1,
          pos_x,
          pos_y,
          gLink.id,
          gLink.data,
          gLink.html
        );
        break;
      case 'facebook-link':
        const fLink = Blocks.getFacebookLink();
        editor.addNode(
          fLink.id,
          1,
          1,
          pos_x,
          pos_y,
          fLink.id,
          fLink.data,
          fLink.html
        );
        break;
      case 'telegram':
        var telegrambot = `
          <div>
            <div class="title-box"><i class="fab fa-telegram-plane"></i> Telegram bot</div>
            <div class="box">
              <p>Send to telegram</p>
              <p>select channel</p>
              <select df-channel>
                <option value="channel_1">Channel 1</option>
                <option value="channel_2">Channel 2</option>
                <option value="channel_3">Channel 3</option>
                <option value="channel_4">Channel 4</option>
              </select>
            </div>
          </div>
          `;
        editor.addNode(
          'telegram',
          1,
          0,
          pos_x,
          pos_y,
          'telegram',
          { channel: 'channel_3' },
          telegrambot
        );
        break;
      case 'aws':
        var aws = `
          <div>
            <div class="title-box"><i class="fab fa-aws"></i> Aws Save </div>
            <div class="box">
              <p>Save in aws</p>
              <input type="text" df-db-dbname placeholder="DB name"><br><br>
              <input type="text" df-db-key placeholder="DB key">
              <p>Output Log</p>
            </div>
          </div>
          `;
        editor.addNode(
          'aws',
          1,
          1,
          pos_x,
          pos_y,
          'aws',
          { db: { dbname: '', key: '' } },
          aws
        );
        break;
      case 'log':
        var log = `
            <div>
              <div class="title-box"><i class="fas fa-file-signature"></i> Save log file </div>
            </div>
            `;
        editor.addNode('log', 1, 0, pos_x, pos_y, 'log', {}, log);
        break;
      case 'google':
        var google = `
            <div>
              <div class="title-box"><i class="fab fa-google-drive"></i> Google Drive save </div>
            </div>
            `;
        editor.addNode('google', 1, 0, pos_x, pos_y, 'google', {}, google);
        break;
      case 'template':
        var template = `
            <div>
              <div class="title-box"><i class="fas fa-code"></i> Template</div>
              <div class="box">
                Ger Vars
                <textarea df-template></textarea>
                Output template with vars
              </div>
            </div>
            `;
        editor.addNode(
          'template',
          1,
          1,
          pos_x,
          pos_y,
          'template',
          { template: 'Write your template' },
          template
        );
        break;
      case 'multiple':
        var multiple = `
            <div>
              <div class="box">
                Multiple!
              </div>
            </div>
            `;
        editor.addNode(
          'multiple',
          3,
          4,
          pos_x,
          pos_y,
          'multiple',
          {},
          multiple
        );
        break;
      case 'personalized':
        var personalized = `
            <div>
              Personalized
            </div>
            `;
        editor.addNode(
          'personalized',
          1,
          1,
          pos_x,
          pos_y,
          'personalized',
          {},
          personalized
        );
        break;
      case 'dbclick':
        var dbclick = `
            <div>
            <div class="title-box"><i class="fas fa-mouse"></i> Db Click</div>
              <div class="box dbclickbox" ondblclick="showpopup(event)">
                Db Click here
                <div class="modal" style="display:none">
                  <div class="modal-content">
                    <span class="close" onclick="closemodal(event)">&times;</span>
                    Change your variable {name} !
                    <input type="text" df-name>
                  </div>

                </div>
              </div>
            </div>
            `;
        editor.addNode(
          'dbclick',
          1,
          1,
          pos_x,
          pos_y,
          'dbclick',
          { name: '' },
          dbclick
        );
        break;

      default:
    }


    if (name.indexOf("cliktracker-link-") !== -1) {
      const w = wireflowIcons.find(w => w.name == name.replace("cliktracker-link-", ""))
      let image = "https://app.mysocial360.com/mysocial360/api/images/icons/wireflow/Article/Article-1.svg"
      if (w) image = w.url;

      const clikTrackerLink = Blocks.getClickTrackerLinkCustom(image, w?.name || "Page");
      editor.addNode(
        clikTrackerLink.id,
        1,
        1,
        pos_x,
        pos_y,
        clikTrackerLink.id,
        clikTrackerLink.data,
        clikTrackerLink.html
      );
    }
  };




  const showpopup = (e) => {
    e.target.closest('.drawflow-node').style.zIndex = '9999';
    e.target.children[0].style.display = 'block';
    //document.getElementById("modalfix").style.display = "block";

    //e.target.children[0].style.transform = 'translate('+translate.x+'px, '+translate.y+'px)';
    setTransform(editorState.precanvas.style.transform);
    editorState.precanvas.style.transform = '';
    editorState.precanvas.style.left = editorState.canvas_x + 'px';
    editorState.precanvas.style.top = editorState.canvas_y + 'px';
    console.log(transform);

    //e.target.children[0].style.top  =  -editor.canvas_y - editor.container.offsetTop +'px';
    //e.target.children[0].style.left  =  -editor.canvas_x  - editor.container.offsetLeft +'px';
    editorState.editor_mode = 'fixed';
  };

  const closemodal = (e) => {
    e.target.closest('.drawflow-node').style.zIndex = '2';
    e.target.parentElement.parentElement.style.display = 'none';
    //document.getElementById("modalfix").style.display = "none";
    editorState.precanvas.style.transform = transform;
    editorState.precanvas.style.left = '0px';
    editorState.precanvas.style.top = '0px';
    editorState.editor_mode = 'edit';
  };

  const changeModule = (event) => {
    const all = document.querySelectorAll('.menu ul li');
    for (let i = 0; i < all.length; i++) {
      all[i].classList.remove('selected');
    }
    event.target.classList.add('selected');
  };

  const changeMode = (option) => {
    if (lockFlow) {
      editorState.editor_mode = 'fixed';
    } else {
      editorState.editor_mode = 'edit';
    }

    return setLockFlow((prev) => !prev);
  };

  const checkAndAddImageToDrawComponent = useCallback(() => {
    const timer = setInterval(() => {
      if (
        JSON.stringify(editorState.drawflow.drawflow.Home.data) !==
        JSON.stringify({})
      ) {
        const imageBtns = document.querySelectorAll('.drawflow-image-upload');
        if (imageBtns) {
          imageBtns.forEach((imageBtn) => {
            const id = imageBtn.closest('.drawflow-node').id.slice(5);
            const node = editorState.getNodeFromId(id);
            const nodeData = node.data;
            const box = imageBtn.parentNode;
            box.setAttribute(
              'style',
              `background-image: url('${nodeData['df-image']}'); 
                background-repeat:no-repeat;
                background-size:100% 100%;
                `
            );
          });
        }
        clearInterval(timer);
      }
    }, 500);
  }, [editorState]);

  const runBtn = useCallback(() => {
    const imageBtns = document.querySelectorAll('.drawflow-image-upload');

    if (imageBtns) {
      imageBtns.forEach((imageBtn) => {
        imageBtn.onchange = async (e) => {
          const id = e.target.closest('.drawflow-node').id.slice(5);
          const type = e.target.name;
          const file = e.target.files[0];
          const formData = new FormData();
          formData.append('file', file);
          const response = API.Post('/images/', formData, token);

          toast.promise(response, {
            loading: 'Uploading Image. Please wait...',
            success: ({ data }) => {
              const node = editorState.getNodeFromId(id);
              const nodeData = node.data;
              nodeData[
                type
              ] = `${API.BASE_URL}/mysocial360/api/images/${data.file.filename}`;
              const box = e.target.parentNode;
              box.setAttribute(
                'style',
                `background-image: url('${API.BASE_URL}/mysocial360/api/images/${data.file.filename}'); 
                background-repeat:no-repeat;
                background-size:100% 100%;
                `
              );
              editorState.updateNodeDataFromId(id, nodeData);
              editorStateDebounceFn(editorState);
              return `Image Uploaded`;
            },
            error: (error) => {
              console.log(error);
              return 'Something went wrong with the upload. Please try again later';
            },
          });
        };
      });
    }
  }, [editorState, token]);

  const imageUploadListener = useCallback(() => {
    const observer = new MutationObserver((mutations) => {
      const deleteBtn = document.querySelectorAll('.drawflow-image-upload');

      if (deleteBtn) {
        runBtn();
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
    });
  }, [runBtn]);

  var elements = document.getElementsByClassName('drag-item');
  for (var i = 0; i < elements.length; i++) {
    elements[i].addEventListener('touchend', drop, false);
    elements[i].addEventListener('touchmove', positionMobile, false);
    elements[i].addEventListener('touchstart', drag, false);
  }
  useEffect(() => {
    checkAndAddImageToDrawComponent();
  }, [editorState.drawflow.drawflow.Home.data]);

  useEffect(() => {
    editorImageUploadListenerDebounceFn();
  }, [editorState]);

  useEffect(() => {
    if (state.publish) {
      const handleSubmit = async (e) => {
        const body = {
          id: id,
          publish: true,
        };

        try {
          const response = await API.Patch(
            platform === 'fb_hacker_challenge'
              ? `/challenge/${id}`
              : '/flows/publish',
            body,
            token
          );
          const data = response.data;
          if (data.success) {
            swal('Flow has been Published', { icon: 'success' }).then(() => {
              navigate(-1);
            });
          }
          if (data.result) {
            swal('Challenge has been Published', { icon: 'success' }).then(
              () => {
                navigate(-1);
              }
            );
          }
        } catch (error) {
          if (error.response.status === 500)
            return toast.error({
              title: 'Something went wrong',
              icon: 'error',
            });
        }
      };
      handleSubmit();

      dispatch({
        type: DRAWFLOW_ACTIONS.PUBLISH_FLOW,
        field: 'publish',
        payload: false,
      });
    }
  }, [dispatch, addFlow, state.publish]);

  return isLoading ? (
    <>
      <BaseStyleComponent>
        <Box
          sx={{
            height: '80vh',
            width: '80vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </BaseStyleComponent>
    </>
  ) : (
    <>
      <div
        style={{
          width: '100%',
          height: '100vh',
          position: 'relative',
          background: 'blue',
          marginTop: '40px',
        }}
      >
        <div
          ref={measuredRef}
          id="drawflow"
          onDrop={drop}
          onDragOver={allowDrop}
        ></div>
        <div className="bar-zoom">
          <IconButton onClick={changeMode}>
            {lockFlow ? <LockOpenIcon /> : <LockIcon />}
          </IconButton>
          <IconButton
            onClick={() => {
              editorState.zoom_in();
            }}
          >
            <ZoomInIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              editorState.zoom_reset();
            }}
          >
            <SearchIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              editorState.zoom_out();
            }}
          >
            <ZoomOutIcon />
          </IconButton>
        </div>
        <Box
          sx={{
            position: 'absolute',
            top: '50',
            left: '0',
            bottom: '50',
            height: '100vh',
            width: open ? '380px' : '27px',
            zIndex: '999',
            background: '#fff',
            transition: '0.2s ease-in-out',
            clear: 'both',
          }}
        >
          <Box onClick={handleDrawer} className="secondary-drawer-opener">
            <Box
              sx={{
                color: '#fff',
                width: '22px',
                height: '22px',
                borderRadius: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'rgba(0, 0, 0, 0.10)',
              }}
            >
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </Box>
          </Box>
          <Box className="secondary-drawer-content" sx={{ display: open ? 'flex' : 'none', }}>
            <Box className="secondary-drawer-item">
              <TextField sx={{ width: "95%" }} type="search" label="Search" size="small" value={search} onChange={e => setSearch(e.target.value)} />

              <br /><br />
              <ContextBOSIcons.Provider value={bosIcons}>
                <ContextWireFlowIcons.Provider value={wireflowIcons}>
                  <ContextSearch.Provider value={search}>
                    {platform === 'fb_hacker_challenge' ? <BlocksInnerCircleContest drag={drag} /> : null}
                    {platform === 'vavicky' ? <BlocksVaVicky drag={drag} /> : null}
                    {platform === 'clikTracker' ? <BlocksCliktracker drag={drag} /> : null}
                  </ContextSearch.Provider>
                </ContextWireFlowIcons.Provider>
              </ContextBOSIcons.Provider>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default DrawPage;
