import { Avatar, Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { apps } from '../constants/apps_data';
import { DrawflowContext } from '../context/drawflowContext';
import { DRAWFLOW_ACTIONS } from '../context/reducers/drawflowReducer';

import BaseStyleComponent from '../components/BaseStyleComponent';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const AppsPage = () => {
  const [state, dispatch] = useContext(DrawflowContext);
  const enabledApps = ['Va Vicky', 'ClikTracker'];

  useEffect(() => {
    dispatch({
      type: DRAWFLOW_ACTIONS.IS_FLOW,
      field: 'isFlow',
      payload: false,
    });
  }, [dispatch, state.isFlow]);
  return (
    <BaseStyleComponent>
      <Box sx={{ marginBottom: '100px' }}>
        <Grid container spacing={2}>
          {apps.map((app) => (
            <Grid item xs={4} key={app.id}>
              <Link
                to={enabledApps.indexOf(app.title) != -1 ? `/apps/${app.id}/flows` : "#"}
                style={{ textDecoration: 'none' }}
              >
                <Item>
                  <Stack direction="row" spacing={2}>
                    <Avatar src={app.logo} />
                    <Typography variant="h6">{enabledApps.indexOf(app.title) != -1 ? app.title : "Coming Soon"}</Typography>
                  </Stack>
                </Item>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </BaseStyleComponent>
  );
};

export default AppsPage;
