import { EmailRounded } from '@mui/icons-material'
import { Card, Stack, Typography } from '@mui/material'
import React from 'react'
import { useCallback } from 'react'
import { useContext } from 'react'
import { FacebookLogo,   GoogleLogo, PageLogo } from '../../utils/logos'
import { ContextBOSIcons, ContextSearch, ContextWireFlowIcons } from './DrawPage'

function BlocksCliktracker({ drag }) {

  const search = useContext(ContextSearch);
  const wireflowIcons = useContext(ContextWireFlowIcons);
  const bosIcons = useContext(ContextBOSIcons);

 
  const filter = useCallback((data) => {
    if (!search) return true;
    if (search.replace(/\s/gmi, '') == '') return true;
    return data.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  }, [search]);


  return (
    <div>
      <Card
        className="drag-item"
        draggable="true"
        onDragStart={drag}
        data-node="email"
        sx={{ marginBottom: '13px' }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <EmailRounded />
          <Typography
            variant="h6"
            sx={{
              color: '#6e6d6d',
              fontSize: '1rem',
              fontWeight: '400',
            }}
          >
            Email
          </Typography>
        </Stack>
      </Card>

      <Card
        className="drag-item"
        draggable="true"
        onDragStart={drag}
        data-node="google-link"
        sx={{ marginBottom: '13px' }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>

          <Typography
            variant="h6"
            sx={{
              color: '#6e6d6d',
              fontSize: '1rem',
              fontWeight: '400',
            }}
          >
            <img src={GoogleLogo} width={30} />
            Google Link
          </Typography>
        </Stack>
      </Card>

      <Card
        className="drag-item"
        draggable="true"
        onDragStart={drag}
        data-node="facebook-link"
        sx={{ marginBottom: '13px' }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>

          <Typography
            variant="h6"
            sx={{
              color: '#6e6d6d',
              fontSize: '1rem',
              fontWeight: '400',
            }}
          >
            <img src={FacebookLogo} width={30} />
            Facebook Link
          </Typography>
        </Stack>
      </Card>

      <Card
        className="drag-item"
        draggable="true"
        onDragStart={drag}
        data-node="cliktracker-link"
        sx={{ marginBottom: '13px' }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>

          <Typography
            variant="h6"
            sx={{
              color: '#6e6d6d',
              fontSize: '1rem',
              fontWeight: '400',
            }}
          >
            <img src={PageLogo} width={30} />
            Page
          </Typography>
        </Stack>
      </Card>

 
      <Typography variant="h4" sx={{ color: '#6e6d6d', fontSize: '1.5rem', fontWeight: '400' }} >
        BOS Customs
      </Typography>

      <hr />

      {
        bosIcons.filter(filter).map(wireflow => (
          <Card className="drag-item" draggable="true" onDragStart={drag} data-node={"cliktracker-link-" + wireflow.name} sx={{ marginBottom: '13px' }} >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                variant="h6" sx={{ verticalAlign: "middle", color: '#6e6d6d', fontSize: '1rem', fontWeight: '400' }} >
                <img src={wireflow.url} width={70} alt={wireflow.name} />
                {wireflow.name}
              </Typography>
            </Stack>
          </Card>
        ))
      }

      <Typography variant="h4" sx={{ color: '#6e6d6d', fontSize: '1.5rem', fontWeight: '400' }} >
        Custom
      </Typography>

      <hr />

      {
        wireflowIcons.filter(filter).map(wireflow => (
          <Card className="drag-item" draggable="true" onDragStart={drag} data-node={"cliktracker-link-" + wireflow.name} sx={{ marginBottom: '13px' }} >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                variant="h6" sx={{ verticalAlign: "middle", color: '#6e6d6d', fontSize: '1rem', fontWeight: '400' }} >
                <img src={wireflow.url} width={70} alt={wireflow.name} />
                {wireflow.name}
              </Typography>
            </Stack>
          </Card>
        ))
      }

    </div>
  )
}

export default BlocksCliktracker