import {
  BosLogo,
  ChatBosLogo,
  CliktrackerLogo,
  CopypasteLogo,
  CRMLogo,
  CursorLogo,
  EmojiLogo,
  FBHackerLogo,
  FBScraperLogo,
  FoeOrBroLogo,
  InnerCircleLogo,
  Mysocial360Logo,
  SplashLogo,
  VaVickyLogo,
  VaWardenLogo,
} from '../utils/logos';

const chatbosData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'chatbos',
          data: {},
          class: 'chatbos',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${ChatBosLogo} width="40" height="40"/>
              Chatbos
            </div>\n
            <div class="box">\n
              <p>Start Your Flow</p>\n 
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { trigger: '', message: '' },
          class: 'message',
          html: `\n<div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;"> 
              Send Message
            </div>\n
            <div class="box">\n 
              <p>Add Trigger</p>
              <input type='text' df-trigger/>\n
              <p>Add Message</p>
              <textarea df-message></textarea>\n
            </div>\n
          </div>\n`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
};


const vavickyData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'vavicky',
          data: {
            welcome: "",
            delay: 3
          },
          class: 'vavicky',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${VaVickyLogo} width="40" height="40"/>
              VA Vicky
            </div>\n
            <div class="box">\n
              <p><strong>Start Your Flow</strong></p>\n 
            </div>\n
            <div class="box">
              <p>Default Message</p>
              <textarea type="text" df-welcome></textarea>
            </div>\n
            <div class="box">
              <p>Reply To New Messages Within (sec)</p>
              <input type="number" df-delay min="1"/>
            </div>
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { trigger: '', message: '', delay: 3 },
          class: 'message',
          html: `\n<div>\n 
          <div>
          <div class="title-box" style="display:flex; align-items:center; font-weight:700;"><img src="images/logo.png" alt="" class="logo">   Send a Message</div>
          <div class="box">
            
            <p>Add Triggers</p>
            <input type="text" df-trigger placeholder="Separate with ,"/>
            <p>Delay (Sec)</p>
            <input type="text" df-delay/>
            <p>Add Message</p> 
            <textarea df-message ></textarea>
          </div>
        </div>`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
}

const crmData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'crm',
          data: {},
          class: 'crm',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${CRMLogo} width="40" height="40"/>
              CRM
            </div>\n
            <div class="box">\n
              <p>Start Your Flow</p>\n 
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { message: '' },
          class: 'message',
          html: `\n<div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${CRMLogo} width="40" height="40"/>
              Send Message
            </div>\n
            <div class="box">\n 
              Add Your Message\n
              <textarea df-message></textarea>\n
            </div>\n
          </div>\n`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
};
const innercircleData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'innercircle_deets',
          data: {},
          class: 'innercircle_deets',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${InnerCircleLogo} width="30" height="30"/> &nbsp;
              Innercircle Deets
            </div>\n
            <div class="box">\n
              <p>Start Your Flow</p>\n 
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { message: '' },
          class: 'message',
          html: `\n<div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${InnerCircleLogo} width="30" height="30"/> &nbsp;
              Send Message
            </div>\n
            <div class="box">\n 
              Add Your Message\n
              <textarea df-message></textarea>\n
            </div>\n
          </div>\n`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
};
const mysocial360Data = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'mysocial360',
          data: {},
          class: 'mysocial360',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${Mysocial360Logo} width="40" height="40"/>
              Mysocial360
            </div>\n
            <div class="box">\n
              <p>Start Your Flow</p>\n 
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { message: '' },
          class: 'message',
          html: `\n<div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${Mysocial360Logo} width="40" height="40"/>
              Send Message
            </div>\n
            <div class="box">\n 
              Add Your Message\n
              <textarea df-message></textarea>\n
            </div>\n
          </div>\n`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
};
const fbScraperData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'fbScraper',
          data: {},
          class: 'fbScraper',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${FBScraperLogo} width="30" height="30"/> &nbsp;
              FB Scraper
            </div>\n
            <div class="box">\n
              <p>Start Your Flow</p>\n 
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { message: '' },
          class: 'message',
          html: `\n<div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${FBScraperLogo} width="30" height="30"/> &nbsp;
              Send Message
            </div>\n
            <div class="box">\n 
              Add Your Message\n
              <textarea df-message></textarea>\n
            </div>\n
          </div>\n`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
};
const storieReelsData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'storieReels',
          data: {},
          class: 'storieReels',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${BosLogo} width="30" height="30"/> &nbsp;
              StorieReels
            </div>\n
            <div class="box">\n
              <p>Start Your Flow</p>\n 
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { message: '' },
          class: 'message',
          html: `\n<div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${BosLogo} width="30" height="30"/> &nbsp;
              Send Message
            </div>\n
            <div class="box">\n 
              Add Your Message\n
              <textarea df-message></textarea>\n
            </div>\n
          </div>\n`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
};
const emojiData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'emojis360',
          data: {},
          class: 'emojis360',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${EmojiLogo} width="50" height="50"/>
              360 Emojis
            </div>\n
            <div class="box">\n
              <p>Start Your Flow</p>\n 
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { message: '' },
          class: 'message',
          html: `\n<div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${EmojiLogo} width="50" height="50"/>
              Send Message
            </div>\n
            <div class="box">\n 
              Add Your Message\n
              <textarea df-message></textarea>\n
            </div>\n
          </div>\n`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
};
const copyPasteData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'copyPaste',
          data: {},
          class: 'copyPaste',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${CopypasteLogo} width="40" height="40"/>
              Chatbos
            </div>\n
            <div class="box">\n
              <p>Start Your Flow</p>\n 
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { message: '' },
          class: 'message',
          html: `\n<div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${CopypasteLogo} width="40" height="40"/>
              Copy Paste 360
            </div>\n
            <div class="box">\n 
              Add Your Message\n
              <textarea df-message></textarea>\n
            </div>\n
          </div>\n`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
};
const clikTrackerData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'clikTracker',
          data: { link: "" },
          class: 'clikTracker',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${CliktrackerLogo} width="30" height="30"/> &nbsp;
              ClikTracker
            </div>\n
            <div class="box">\n
              <p>Start Your Funnel</p>\n 
              <input df-link placeholder="Link"></input>
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: []  },
          },
          pos_x: 300,
          pos_y: 191,
        }
      },
    },
  },
};
const customCursorData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'customCursor',
          data: {},
          class: 'customCursor',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${CursorLogo} width="40" height="40"/>
              Custom Cursor
            </div>\n
            <div class="box">\n
              <p>Start Your Flow</p>\n 
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { message: '' },
          class: 'message',
          html: `\n<div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${CursorLogo} width="40" height="40"/>
              Send Message
            </div>\n
            <div class="box">\n 
              Add Your Message\n
              <textarea df-message></textarea>\n
            </div>\n
          </div>\n`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
};
const foeOrBroData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'foeOrBro',
          data: {},
          class: 'foeOrBro',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${FoeOrBroLogo} width="30" height="30"/>
              Foe Or Bro
            </div>\n
            <div class="box">\n
              <p>Start Your Flow</p>\n 
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { message: '' },
          class: 'message',
          html: `\n<div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${FoeOrBroLogo} width="30" height="30"/>
              Send Message
            </div>\n
            <div class="box">\n 
              Add Your Message\n
              <textarea df-message></textarea>\n
            </div>\n
          </div>\n`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
};
const vaWardenData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'vaWarden',
          data: {},
          class: 'vaWarden',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${VaWardenLogo} width="40" height="40"/>
              Va Warden
            </div>\n
            <div class="box">\n
              <p>Start Your Flow</p>\n 
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { message: '' },
          class: 'message',
          html: `\n<div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${VaWardenLogo} width="40" height="40"/>
              Send Message
            </div>\n
            <div class="box">\n 
              Add Your Message\n
              <textarea df-message></textarea>\n
            </div>\n
          </div>\n`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
};
const fbHackerData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'fbHacker',
          data: {},
          class: 'fbHacker',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${FBHackerLogo} width="40" height="40"/>
              FB Hacker
            </div>\n
            <div class="box">\n
              <p>Start Your Flow</p>\n 
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { message: '' },
          class: 'message',
          html: `\n<div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${FBHackerLogo} width="40" height="40"/>
              Send Message
            </div>\n
            <div class="box">\n 
              Add Your Message\n
              <textarea df-message></textarea>\n
            </div>\n
          </div>\n`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
};

const fbHackerChallengeData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'fbHacker',
          data: {},
          class: 'fbHacker',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${FBHackerLogo} width="40" height="40"/>
              FB Challenge
            </div>\n
            <div class="box">\n
              <p>Start Your Flow</p>\n 
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { message: '' },
          class: 'message',
          html: `\n<div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${FBHackerLogo} width="40" height="40"/>
              Your Content
            </div>\n
            <div class="box">\n 
              Add Your Content\n
              <textarea df-message></textarea>\n
            </div>\n
          </div>\n`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
};


const splashData = {
  drawflow: {
    Home: {
      data: {
        1: {
          id: 1,
          name: 'splashMysocial',
          data: {},
          class: 'splashMysocial',
          html: `\n <div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${SplashLogo} width="30" height="30"/>&nbsp;
              Splash Mysocial
            </div>\n
            <div class="box">\n
              <p>Start Your Flow</p>\n 
            </div>\n
          </div>\n `,
          typenode: false,
          inputs: {},
          outputs: {
            output_1: { connections: [{ node: '2', output: 'input_1' }] },
          },
          pos_x: 300,
          pos_y: 191,
        },
        2: {
          id: 2,
          name: 'Send Message',
          data: { message: '' },
          class: 'message',
          html: `\n<div>\n 
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
              <img src=${SplashLogo} width="30" height="30"/>&nbsp;
              Send Message
            </div>\n
            <div class="box">\n  
              Add Your Message\n
              <textarea df-message></textarea>\n
            </div>\n
          </div>\n`,
          typenode: false,
          inputs: {
            input_1: { connections: [{ node: '1', input: 'output_1' }] },
          },
          outputs: {
            output_1: {
              connections: [],
            },
          },
          pos_x: 607,
          pos_y: 191,
        },
      },
    },
  },
};

export {
  chatbosData,
  crmData,
  innercircleData,
  mysocial360Data,
  fbScraperData,
  storieReelsData,
  emojiData,
  copyPasteData,
  clikTrackerData,
  customCursorData,
  foeOrBroData,
  vaWardenData,
  fbHackerData,
  fbHackerChallengeData,
  splashData,
  vavickyData
};
