import { CircularProgress, Stack } from '@mui/material';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AUTH_ACTIONS } from '../context/reducers/authReducer';
import { useAuthContext } from '../hooks/useAuthContext';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const OAuthPage = () => {
  const [searchParams] = useSearchParams();
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const name = searchParams.get('name');
  const image = searchParams.get('image');
  const email = searchParams.get('email');

  const notify = () => toast.success(`Welcome ${name?.split(' ')[0]}`);
  const auth = useCallback(async () => {
    const user = {
      name,
      image,
      email,
    };
    Cookies.set('user', JSON.stringify(user));
    Cookies.set('access_token', token);
    dispatch({
      type: AUTH_ACTIONS.LOGIN_SUCCESS,
      payload: { token, user },
    });

    return navigate('/', { replace: true, state: { login: true } });
  }, [dispatch, email, image, name, navigate, token]);

  useEffect(() => {
    auth();
    setTimeout(() => notify(), 500);
  }, []);

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%', height: '100vh' }}
      >
        <CircularProgress />
      </Stack>
    </>
  );
};

export default OAuthPage;
