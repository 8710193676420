import React, { useEffect, useContext } from 'react';
import { DrawflowContext } from '../context/drawflowContext';
import { DRAWFLOW_ACTIONS } from '../context/reducers/drawflowReducer';
import BaseStyleComponent from '../components/BaseStyleComponent';
import ComingSoonComponent from '../components/ComingSoonComponent';

const BroadcastPage = ({ setIsFlow }) => {
  const [state, dispatch] = useContext(DrawflowContext);
  useEffect(() => {
    dispatch({
      type: DRAWFLOW_ACTIONS.IS_FLOW,
      field: 'isFlow',
      payload: false,
    });
  }, [dispatch, state.isFlow]);
  return (
    <>
      <BaseStyleComponent>
        <ComingSoonComponent />
      </BaseStyleComponent>
    </>
  );
};

export default BroadcastPage;
