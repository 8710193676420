export const drawflowInitalState = {
  loading: true,
  saving: false,
  isFlow: false,
  publish: false,
  calendarChallenges: [],
  challenges: [],
  flows: [],
  flow: {
    flowName: '',
    drawflow: {},
  },
  challenge: {
    name: '',
    days: 5,
    start: new Date().toJSON().replace(/T.*/gim, ''),
    data: [],
  },
};

export const DRAWFLOW_ACTIONS = {
  IS_FLOW: 'IS_FLOW',
  PUBLISH_FLOW: 'PUBLISH_FLOW',
  RESET_FLOW: 'RESET_FLOW',
  HANDLE_FLOW: 'HANDLE_FLOW',
  HANDLE_FLOWS: 'HANDLE_FLOWS',
  RESET_CHALLENGE: 'RESET_CHALLENGE',
  HANDLE_CHALLENGE: 'HANDLE_CHALLENGE',
  HANDLE_CHALLENGES: 'HANDLE_CHALLENGES',
  HANDLE_CALENDAR_CHALLENGES: 'HANDLE_CALENDAR_CHALLENGES',
  LOADING: 'LOADING',
  SAVING: 'SAVING',
};

export const drawflowReducer = (state, action) => {
  switch (action.type) {
    case DRAWFLOW_ACTIONS.IS_FLOW:
      return {
        ...state,
        [action.field]: action.payload,
      };
    case DRAWFLOW_ACTIONS.PUBLISH_FLOW:
      return {
        ...state,
        [action.field]: action.payload,
      };
    case DRAWFLOW_ACTIONS.HANDLE_FLOW:
      return {
        ...state,
        [action.field]: {
          ...state.flow,
          [action.payload.name]: action.payload.value,
        },
      };
    case DRAWFLOW_ACTIONS.HANDLE_CHALLENGE:
      return {
        ...state,
        [action.field]: {
          ...state.challenge,
          ...action.payload,
        },
      };
    case DRAWFLOW_ACTIONS.RESET_FLOW:
      return {
        ...state,
        flow: { flowName: '', drawflow: {} },
      };
    case DRAWFLOW_ACTIONS.RESET_CHALLENGE:
      return {
        ...state,
        challenge: {
          name: '',
          days: 5,
          start: new Date().toJSON().replace(/T.*/gim, ''),
          data: [],
        },
      };
    case DRAWFLOW_ACTIONS.HANDLE_FLOWS:
      return {
        ...state,
        [action.field]: action.payload,
      };
    case DRAWFLOW_ACTIONS.HANDLE_CHALLENGES:
      return {
        ...state,
        [action.field]: action.payload,
      };
    case DRAWFLOW_ACTIONS.HANDLE_CALENDAR_CHALLENGES:
      return {
        ...state,
        [action.field]: action.payload,
      };
    case DRAWFLOW_ACTIONS.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case DRAWFLOW_ACTIONS.SAVING:
      return {
        ...state,
        saving: action.payload,
      };

    default:
      return state;
  }
};
