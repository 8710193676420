import './App.css';
import { Box } from '@mui/material';
import DrawPage from './pages/drawFlowPage/DrawPage';
import HomePage from './pages/HomePage';
import AppsPage from './pages/AppsPage';
import { Routes, Route } from 'react-router-dom';
import LivechatPage from './pages/LivechatPage';
import BroadcastPage from './pages/BroadcastPage';
import SettingsPage from './pages/SettingsPage';
import ContactsPage from './pages/ContactsPage';
import { DrawflowContextProvider } from './context/drawflowContext';
import AppFlowsPage from './pages/AppFlowsPage';
import Login from './pages/Login';
import Register from './pages/Register';
import React from 'react';
import PrivateRoutes from './utils/PrivateRoutes';
import AuthRoutes from './utils/AuthRoutes';
import { AuthContextProvider } from './context/authContext';
import OAuthPage from './pages/OAuthPage';
import { Toaster } from 'react-hot-toast';
import ChallengesPage from './pages/ChallengesPage';
import MiniDrawer from './components/MiniDrawer';

function App() {
  return (
    <Box sx={{ display: 'flex' }}>
      <Toaster />
      <AuthContextProvider>
        <DrawflowContextProvider>
          <Routes>
            <Route path="/auth" element={<OAuthPage />} />
            <Route element={<AuthRoutes />}>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route element={<MiniDrawer />}>
                <Route exact index path="/" element={<HomePage />} />
                <Route path="/apps">
                  <Route index element={<AppsPage />} />
                  <Route path=":id/flows" element={<AppFlowsPage />} />
                  <Route path=":platform/flows/:id" element={<DrawPage />} />
                </Route>
                <Route path="/contacts" element={<ContactsPage />} />
                <Route path="/livechats" element={<LivechatPage />} />
                <Route path="/broadcast" element={<BroadcastPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/challenges" element={<ChallengesPage />} />
              </Route>
            </Route>
          </Routes>
        </DrawflowContextProvider>
      </AuthContextProvider>
    </Box>
  );
}

export default App;
