import {
  AppBar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';
import BaseStyleComponent from '../components/BaseStyleComponent';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PublishIcon from '@mui/icons-material/Publish';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { DRAWFLOW_ACTIONS } from '../context/reducers/drawflowReducer';
import { DrawflowContext } from '../context/drawflowContext';
import NewFlowModal from '../components/NewFlowModal';
import API from '../utils/api';
import {
  chatbosData,
  clikTrackerData,
  copyPasteData,
  crmData,
  customCursorData,
  emojiData,
  fbHackerData,
  fbScraperData,
  foeOrBroData,
  innercircleData,
  mysocial360Data,
  splashData,
  storieReelsData,
  vaWardenData,
  vavickyData
} from '../constants/drawflow_default_data';
import swal from 'sweetalert';
import EmptyBoxAnimation from '../assets/lottie_files/lottie_empty_box.json';
import Lottie from 'react-lottie';
import { useAuthContext } from '../hooks/useAuthContext';
import toast from 'react-hot-toast';
import NewChallengeModal from '../components/NewChallengeModal';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const AppFlowsPage = () => {
  const theme = useTheme();
  const [state, dispatch] = useContext(DrawflowContext);
  const { token } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openChallenge, setOpenChallenge] = useState(false);
  const [editId, setEditId] = useState('');
  const [tabValue, setTabValue] = useState(0);

  const navigate = useNavigate();
  const { id } = useParams();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: EmptyBoxAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getChallenges = useCallback(async () => {
    if (token && id === 'fb_hacker') {
      try {
        dispatch({
          type: DRAWFLOW_ACTIONS.LOADING,
          payload: true,
        });
        const response = await API.Get(`/challenge`, token);

        if (response.data) {
          const list = response.data;
          const flows = [];
          list.forEach((flow) => {
            const arr = {
              ...flow,
              id: flow._id,
              start: new Date(flow.start).toString(),
            };
            flows.push(arr);
          });
          dispatch({
            type: DRAWFLOW_ACTIONS.HANDLE_CHALLENGES,
            field: 'challenges',
            payload: flows,
          });
          dispatch({
            type: DRAWFLOW_ACTIONS.LOADING,
            payload: false,
          });
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  }, [token, dispatch, id]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTabChangeIndex = (index) => {
    setTabValue(index);
  };

  const handleNavFlow = useCallback(
    (itemId) => {
      navigate(`/apps/${id}/flows/${itemId}`, {
        state: {
          name: '',
          flow: '',
          platform: id,
          id: itemId,
        },
      });
    },
    [id, navigate]
  );

  const handleNavChallenge = useCallback(
    (itemId) => {
      navigate(`/apps/fb_hacker_challenge/flows/${itemId}`, {
        state: {
          platform: 'fb_hacker_challenge',
          id: itemId,
        },
      });
    },
    [navigate]
  );

  /**
   *
   * @param {*} id
   * @param {*} name
   * @returns setOpen(true) with default drawflow options if ID is empty to create a new flow, otherwise
   * opens modal to edit flow name
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOpen = (id, name) => {
    if (id === '') {
      dispatch({ type: DRAWFLOW_ACTIONS.RESET_FLOW });
      checkType();
      return setOpen(true);
    }
    console.log(name);
    dispatch({
      type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
      field: 'flow',
      payload: { name: 'flowName', value: name },
    });
    setEditId(id);
    setIsEdit(true);
    setOpen(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOpenChallenge = (id, name, days, start) => {
    if (id === '') {
      dispatch({ type: DRAWFLOW_ACTIONS.RESET_CHALLENGE });
      return setOpenChallenge(true);
    }
    dispatch({
      type: DRAWFLOW_ACTIONS.HANDLE_CHALLENGE,
      field: 'challenge',
      payload: { name, days, start },
    });
    setEditId(id);
    setIsEdit(true);
    setOpenChallenge(true);
  };

  const handleClose = () => {
    setIsEdit(false);
    setOpen(false);
  };

  const handleCloseChallenge = () => {
    setIsEdit(false);
    setOpenChallenge(false);
  };

  /**
   *
   * @param {*} e
   * handle edit of flow name by adding to reducer state
   */
  const handleFlowName = (e) => {
    dispatch({
      type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
      field: 'flow',
      payload: { name: 'flowName', value: e.target.value },
    });
  };

  /**
   *
   * @returns
   */
  const handleStartFlow = async (e) => {
    e.preventDefault();
    if (!state.flow.flowName) return toast.error('Name is required');

    try {
      const response = API.Post(
        '/flows/add',
        {
          name: state.flow.flowName,
          flow: { drawflow: state.flow.drawflow },
          type: id,
        },
        token
      );

      toast.promise(response, {
        loading: 'Creating new Flow...',
        success: ({ data }) => {
          navigate(`/apps/${id}/flows/${data.response._id}`, {
            state: {
              flow: data.response.flow,
              name: data.response.name,
              platform: id,
              id: data.response._id,
            },
          });
          return 'Flow Created';
        },
        error: 'Error creating Flow',
      });
    } catch (error) {
      console.log(error);
      if (error.response.status === 400)
        return swal({
          title: `${error.response.data.message}`,
          icon: 'warning',
        });
      if (error.response.status === 500)
        return swal({ title: 'Something went wrong', icon: 'error' });
    }
  };

  const handleCreateChallenge = () => {
    if (state.challenge.name.replace(/\s/gim, '') === '')
      return swal('Please enter name');
    if (isNaN(parseInt(state.challenge.days)))
      return swal('Days should to be a number');
    if (parseInt(state.challenge.days) < 1)
      return swal('Challenge should be at least one day.');

    const timeInADay = 24 * 60 * 60 * 1000; //milliseconds
    if (Date.now() - new Date(state.challenge.start).getTime() > timeInADay)
      return swal("Challenge can't run on past dates.");

    const response = API.Post(`/challenge`, state.challenge, token);

    toast.promise(response, {
      loading: 'Creating new Challenge...',
      success: ({ data }) => {
        handleClose();
        navigate(`/apps/fb_hacker_challenge/flows/${data.challenge._id}`, {
          state: {
            platform: 'fb_hacker_challenge',
            id: data.challenge._id,
          },
        });
        return 'Challenge Created';
      },
      error: ({ response }) => {
        switch (response.status) {
          case 400:
            return response.data.message;
          default:
            return 'Error creating Flow';
        }
      },
    });
  };

  const handleChallengeChange = (e) => {
    dispatch({
      type: DRAWFLOW_ACTIONS.HANDLE_CHALLENGE,
      field: 'challenge',
      payload: { [e.target.name]: e.target.value },
    });
  };

  const handleChallengeDateChange = (date) => {
    dispatch({
      type: DRAWFLOW_ACTIONS.HANDLE_CHALLENGE,
      field: 'challenge',
      payload: { start: date },
    });
  };

  const checkType = useCallback(() => {
    switch (id) {
      case 'chatbos':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: chatbosData.drawflow },
        });
      case 'vavicky':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: vavickyData.drawflow },
        });
      case 'crm':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: crmData.drawflow },
        });
      case 'innercircle_deets':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: innercircleData.drawflow },
        });
      case 'mysocial360':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: mysocial360Data.drawflow },
        });
      case 'fb_scraper':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: fbScraperData.drawflow },
        });
      case 'storieReels':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: storieReelsData.drawflow },
        });
      case '360_emojis':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: emojiData.drawflow },
        });
      case 'copy_paste_360':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: copyPasteData.drawflow },
        });
      case 'clikTracker':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: clikTrackerData.drawflow },
        });
      case 'custom_cursors':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: customCursorData.drawflow },
        });
      case 'foe_or_bro':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: foeOrBroData.drawflow },
        });
      case 'va_warden':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: vaWardenData.drawflow },
        });
      case 'fb_hacker':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: fbHackerData.drawflow },
        });
      case 'splash':
        return dispatch({
          type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
          field: 'flow',
          payload: { name: 'drawflow', value: splashData.drawflow },
        });
      default:
        break;
    }
  }, [dispatch, id]);

  const getFlows = useCallback(async () => {
    try {
      dispatch({
        type: DRAWFLOW_ACTIONS.LOADING,
        payload: true,
      });

      const response = await API.Get(`/flows/flows/${id}`, token);
      const flows = [];
      response.data.forEach((flow) => {
        const arr = {
          id: flow._id,
          name: flow.name,
          flow: flow.flow,
          publish: flow.publish,
        };
        flows.push(arr);
      });

      dispatch({
        type: DRAWFLOW_ACTIONS.HANDLE_FLOWS,
        field: 'flows',
        payload: flows,
      });
      dispatch({
        type: DRAWFLOW_ACTIONS.LOADING,
        payload: false,
      });
    } catch (error) {
      console.log(error);
      if (error.response.status === 500)
        return swal({ title: 'Something went wrong', icon: 'error' });
    }
  }, [dispatch, id, token]);

  useEffect(() => {
    dispatch({
      type: DRAWFLOW_ACTIONS.IS_FLOW,
      field: 'isFlow',
      payload: false,
    });
    getFlows();
    getChallenges();
  }, [getFlows, dispatch, getChallenges]);

  const handleEditName = async (e) => {
    e.preventDefault();
    try {
      const res = API.Patch(
        `/flows/editName/${editId}`,
        {
          name: state.flow.flowName,
        },
        token
      );
      toast.promise(res, {
        loading: 'Loading...',
        success: (data) => {
          setEditId('');
          getFlows();
          handleClose();
          return 'Edit Successful';
        },
        error: 'Error Editing',
      });
    } catch (error) {
      if (error.response.status === 500)
        return toast.error('Something went wrong');
    }
  };

  const handleEditChallenge = async () => {
    const body = {
      name: state.challenge.name,
      days: state.challenge.days,
      start: state.challenge.start,
    };
    const response = API.Patch(`/challenge/${editId}`, body, token);
    toast.promise(response, {
      loading: 'Loading...',
      success: (data) => {
        setEditId('');
        getChallenges();
        handleCloseChallenge();
        return 'Edit Successful';
      },
      error: ({ response }) => {
        switch (response.status) {
          case 400:
            return response.data.message;
          default:
            return 'Error Editing';
        }
      },
    });
  };

  const handlePublish = useCallback(
    async (flowId, status) => {
      const body = {
        id: flowId,
        publish: status,
      };

      try {
        const response = API.Patch('/flows/publish', body, token);

        toast.promise(response, {
          loading: `${status ? 'Publishing' : 'Unpublishing'}`,
          success: (data) => {
            getFlows();
            return `Flow has been ${status ? 'Published' : 'Unpublished'}`;
          },
          error: `Error ${status ? 'Publishing' : 'Unpublishing'}`,
        });
      } catch (error) {
        if (error.response.status === 500)
          return toast.error('Something went wrong');
      }
    },
    [getFlows, token]
  );

  const handlePublishChallenge = useCallback(
    async (flowId, status) => {
      const body = { publish: status };
      try {
        const response = API.Patch(`/challenge/${flowId}`, body, token);

        toast.promise(response, {
          loading: `${status ? 'Publishing' : 'Unpublishing'}`,
          success: (data) => {
            getChallenges();
            return `Challenge has been ${status ? 'Published' : 'Unpublished'}`;
          },
          error: `Error ${status ? 'Publishing' : 'Unpublishing'}`,
        });
      } catch (error) {
        if (error.response.status === 500)
          return toast.error('Something went wrong');
      }
    },
    [getChallenges, token]
  );

  const handleDuplicateFlow = useCallback(
    async (id) => {
      const body = {
        id,
      };
      try {
        const response = API.Post('/flows/duplicate', body, token);
        toast.promise(response, {
          loading: 'Duplicating',
          success: (data) => {
            getFlows();
            return 'Flow has been Duplicated';
          },
          error: 'Error Duplicating',
        });
      } catch (error) {
        if (error.response.status === 500)
          return toast.error('Something went wrong');
      }
    },
    [getFlows, token]
  );

  const handleDuplicateChallenge = useCallback(
    async (id) => {
      const body = {
        id,
      };
      const response = API.Post('challenge/duplicate', body, token);
      toast.promise(response, {
        loading: 'Duplicating',
        success: (data) => {
          getChallenges();
          return 'Challenge has been Duplicated';
        },
        error: ({ response }) => {
          switch (response.status) {
            case 400:
              break;
            default:
              return 'Error Duplicating';
          }
        },
      });
    },
    [getChallenges, token]
  );

  const handleDelete = useCallback(
    async (id) => {
      swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this Flow!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          try {
            const res = API.Delete(`/flows/${id}`, token);

            toast.promise(res, {
              loading: 'Deleting',
              success: (data) => {
                getFlows();
                return 'Flow has been Deleted';
              },
              error: 'Error Deleting',
            });
          } catch (error) {
            console.log(error);
            if (error.response.status === 500)
              return swal({ title: 'Something went wrong', icon: 'error' });
          }
        } else {
          swal('Your Flow is safe!');
        }
      });
    },
    [getFlows, token]
  );

  const handleDeleteChallenge = useCallback(
    async (id) => {
      swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this Flow!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          try {
            const res = API.Delete(`challenge/${id}`, token);

            toast.promise(res, {
              loading: 'Deleting',
              success: (data) => {
                getChallenges();
                return 'Challenge has been Deleted';
              },
              error: 'Error Deleting',
            });
          } catch (error) {
            console.log(error);
            if (error.response.status === 500)
              return swal({ title: 'Something went wrong', icon: 'error' });
          }
        } else {
          swal('Your Flow is safe!');
        }
      });
    },
    [getChallenges, token]
  );

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        width: 150,
        editable: false,
      },
      {
        field: 'publish',
        headerName: 'Flow Status',
        width: 150,
        align: 'center',
        editable: false,
        renderCell: ({ row: { publish } }) => {
          return publish ? (
            <Chip label="Published" color="success" />
          ) : (
            <Chip label="Unpublished" color="warning" />
          );
        },
      },
      {
        field: 'modifiedAt',
        headerName: 'Modified',
        width: 210,
        editable: false,
        renderCell: ({ row: { modifiedAt } }) => {
          return moment(modifiedAt).format('MMMM DD, YYYY, h:mm:ss a');
        },
      },
      {
        field: 'actions',
        type: 'actions',
        sortable: false,
        width: 160,
        getActions: ({ id, row: { name, publish, flow } }) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View Flow"
            showInMenu
            onClick={() => handleNavFlow(id)}
          />,
          <GridActionsCellItem
            icon={publish ? <UnpublishedIcon /> : <PublishIcon />}
            label={publish ? 'Unpublish' : 'Publish'}
            showInMenu
            onClick={() => handlePublish(id, !publish)}
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Rename"
            showInMenu
            onClick={() => {
              handleOpen(id, name);
            }}
          />,
          <GridActionsCellItem
            icon={<ContentCopyIcon />}
            label="Duplicate"
            showInMenu
            onClick={() => handleDuplicateFlow(id)}
          />,
          <GridActionsCellItem
            icon={<DeleteOutlineIcon color="error" />}
            label="Delete"
            showInMenu
            onClick={() => handleDelete(id)}
          />,
        ],
      },
    ],
    [
      handleNavFlow,
      handleDelete,
      handleOpen,
      handleDuplicateFlow,
      handlePublish,
    ]
  );

  const columnChallenges = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        width: 150,
        editable: false,
      },
      {
        field: 'publish',
        headerName: 'Challenge Status',
        width: 150,
        align: 'center',
        editable: false,
        renderCell: ({ row: { publish } }) => {
          return publish ? (
            <Chip label="Published" color="success" />
          ) : (
            <Chip label="Unpublished" color="warning" />
          );
        },
      },
      {
        field: 'days',
        headerName: 'Days',
        width: 90,
        align: 'center',
        editable: false,
      },
      {
        field: 'start',
        headerName: 'Start Date',
        width: 200,
        align: 'center',
        editable: false,
      },
      {
        field: 'updatedAt',
        headerName: 'Modified',
        width: 210,
        editable: false,
        renderCell: ({ row: { updatedAt } }) => {
          return moment(updatedAt).format('MMMM DD, YYYY, h:mm:ss a');
        },
      },
      {
        field: 'actions',
        type: 'actions',
        sortable: false,
        width: 160,
        getActions: ({ id, row: { name, publish, start, days } }) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View Flow"
            showInMenu
            onClick={() => handleNavChallenge(id)}
          />,
          <GridActionsCellItem
            icon={publish ? <UnpublishedIcon /> : <PublishIcon />}
            label={publish ? 'Unpublish' : 'Publish'}
            showInMenu
            onClick={() => handlePublishChallenge(id, !publish)}
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit Challenge"
            showInMenu
            onClick={() => handleOpenChallenge(id, name, days, start)}
          />,
          <GridActionsCellItem
            icon={<ContentCopyIcon />}
            label="Duplicate"
            showInMenu
            onClick={() => handleDuplicateChallenge(id)}
          />,
          <GridActionsCellItem
            icon={<DeleteOutlineIcon color="error" />}
            label="Delete"
            showInMenu
            onClick={() => handleDeleteChallenge(id)}
          />,
        ],
      },
    ],
    [
      handleDeleteChallenge,
      handleDuplicateChallenge,
      handleNavChallenge,
      handleOpenChallenge,
      handlePublishChallenge,
    ]
  );

  return (
    <BaseStyleComponent>
      <Stack spacing={id === 'fb_hacker' ? 3 : 4}>
        <Stack
          direction="row"
          justifyContent="end"
          alignItems="start"
          spacing={2}
        >
          <Button variant="contained" onClick={() => handleOpen('', '')}>
            Start a New Flow
          </Button>
          {id === 'fb_hacker' ? (
            <Button
              variant="contained"
              color="warning"
              onClick={() => handleOpenChallenge('', '', '', '')}
            >
              <EmojiEventsIcon />
              Create Challenge
            </Button>
          ) : null}
        </Stack>

        <Box>
          {id === 'fb_hacker' ? (
            <>
              <AppBar position="static" color="inherit" sx={{ height: '48px' }}>
                <Tabs value={tabValue} onChange={handleTabChange} centered>
                  <Tab label="Flows" />
                  <Tab label="Challenges" />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tabValue}
                onChangeIndex={handleTabChangeIndex}
              >
                <TabPanel value={tabValue} index={0} dir={theme.direction}>
                  {state.loading ? (
                    <Box
                      sx={{
                        height: '80vh',
                        width: '80vw',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : state.flows.length < 1 ? (
                    <Stack alignItems="center">
                      <Lottie
                        options={defaultOptions}
                        height={300}
                        width={300}
                      />
                      <Typography variant="h6">
                        Flows not found!!! Click
                        <strong> Start a New Flow </strong>
                        to get started
                      </Typography>
                    </Stack>
                  ) : (
                    <Box sx={{ height: 400, width: '100%' }}>
                      <DataGrid
                        rows={state.flows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        // checkboxSelection
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                      />
                    </Box>
                  )}
                </TabPanel>
                <TabPanel value={tabValue} index={1} dir={theme.direction}>
                  {state.loading ? (
                    <Box
                      sx={{
                        height: '80vh',
                        width: '80vw',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : state.challenges.length < 1 ? (
                    <Stack alignItems="center">
                      <Lottie
                        options={defaultOptions}
                        height={300}
                        width={300}
                      />
                      <Typography variant="h6">
                        Challenges not found!!! Click
                        <strong> Create Challenge </strong>
                        to get started
                      </Typography>
                    </Stack>
                  ) : (
                    <Box sx={{ height: 400, width: '100%' }}>
                      <>
                        <DataGrid
                          rows={state.challenges}
                          columns={columnChallenges}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          // checkboxSelection
                          disableSelectionOnClick
                          experimentalFeatures={{ newEditingApi: true }}
                        />
                      </>
                    </Box>
                  )}
                </TabPanel>
              </SwipeableViews>
            </>
          ) : state.loading ? (
            <Box
              sx={{
                height: '80vh',
                width: '80vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : state.flows.length < 1 ? (
            <Stack alignItems="center">
              <Lottie options={defaultOptions} height={300} width={300} />
              <Typography variant="h6">
                Flows not found!!! Click
                <strong> Start a New Flow </strong>
                to get started
              </Typography>
            </Stack>
          ) : (
            <Box sx={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={state.flows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                // checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>
          )}
        </Box>
      </Stack>
      <NewFlowModal
        open={open}
        isEdit={isEdit}
        handleEditName={handleEditName}
        flowName={state.flow.flowName}
        handleFlowName={handleFlowName}
        handleStartFlow={handleStartFlow}
        handleClose={handleClose}
      />

      <NewChallengeModal
        open={openChallenge}
        isEdit={isEdit}
        handleClose={handleCloseChallenge}
        handleChallengeChange={handleChallengeChange}
        handleChallengeDateChange={handleChallengeDateChange}
        challenge={state.challenge}
        handleEditChallenge={handleEditChallenge}
        handleCreateChallenge={handleCreateChallenge}
      />
    </BaseStyleComponent>
  );
};

export default AppFlowsPage;
