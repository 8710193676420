import React, { useContext, useEffect } from 'react';
import { DrawflowContext } from '../context/drawflowContext';
import { DRAWFLOW_ACTIONS } from '../context/reducers/drawflowReducer';
import BaseStyleComponent from '../components/BaseStyleComponent';
import ComingSoonComponent from '../components/ComingSoonComponent';
import { useNavigate } from 'react-router-dom';


const HomePage = ({ setIsFlow }) => {
  const [state, dispatch] = useContext(DrawflowContext);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: DRAWFLOW_ACTIONS.IS_FLOW,
      field: 'isFlow',
      payload: false,
    });

    navigate('/apps')
  }, []);
  return (
    <>
      <BaseStyleComponent>
        <ComingSoonComponent />
      </BaseStyleComponent>
    </>
  );
};

export default HomePage;
