import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import FullCalendar from '@fullcalendar/react';
import momentPlugin from '@fullcalendar/moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useAuthContext } from '../hooks/useAuthContext';
import { DrawflowContext } from '../context/drawflowContext';
import { DRAWFLOW_ACTIONS } from '../context/reducers/drawflowReducer';
import API from '../utils/api';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import toast from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PublishIcon from '@mui/icons-material/Publish';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import PaletteIcon from '@mui/icons-material/Palette';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import getChallengeFlow from '../constants/challengeFlow';
import 'animate.css';
import ColorPaletteComponent from '../components/ColorPaletteComponent';

const ChallengesPage = () => {
  const [state, dispatch] = useContext(DrawflowContext);
  const { token } = useAuthContext();
  const calendarRef = useRef();
  const navigate = useNavigate();
  const swal = withReactContent(Swal);

  const [hoverPopOverText, setHoverPopOverText] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [eventClicked, setEventClicked] = useState(null);
  const [eventColor, setEventColor] = useState('#F7954D');

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.el);
    setHoverPopOverText(event.event.title);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
    setEventClicked(null);
  };

  const open = Boolean(anchorEl);
  const openMenu = Boolean(menuAnchorEl);

  const getCalendarColorSettings = async () => {
    try {
      const res = await API.Get('/calendar/', token);
      if (res.data.result) {
        const calendarSettings = res.data.calendarColorSettings;

        if (calendarSettings) {
          document.documentElement.style.setProperty(
            '--calendar-background-url',
            `url(${calendarSettings.backgroundColor})`
          );
          document.documentElement.style.setProperty(
            '--calendar-background-color',
            calendarSettings.backgroundColor
          );
          document.documentElement.style.setProperty(
            '--calendar-day-top-color',
            calendarSettings.dayTopColor
          );
          document.documentElement.style.setProperty(
            '--calendar-day-frame-color',
            calendarSettings.dayFrameColor
          );
          document.documentElement.style.setProperty(
            '--calendar-today-color',
            calendarSettings.todayColor
          );
          document.documentElement.style.setProperty(
            '--calendar-buttons-color',
            calendarSettings.buttonsColor
          );
          document.documentElement.style.setProperty(
            '--calendar-buttons-font-color',
            calendarSettings.buttonsFontColor
          );
          document.documentElement.style.setProperty(
            '--calendar-day-font-color',
            calendarSettings.dayFontColor
          );
          document.documentElement.style.setProperty(
            '--calendar-date-font-color',
            calendarSettings.dateFontColor
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch({
      type: DRAWFLOW_ACTIONS.IS_FLOW,
      field: 'isFlow',
      payload: false,
    });
  }, [dispatch, state.isFlow]);

  const addDays = (startDate, daysToAdd) => {
    const m = new Date(startDate);
    m.setDate(m.getDate() + parseInt(daysToAdd));
    return m.toISOString().replace(/T.*$/, '');
  };

  const getChallenges = useCallback(async () => {
    if (token) {
      try {
        dispatch({
          type: DRAWFLOW_ACTIONS.LOADING,
          payload: true,
        });
        const response = await API.Get(`/challenge`, token);

        if (response.data) {
          console.log(response.data[0]);
          const flows = response.data.map((flow) => ({
            id: flow._id,
            title: flow.name,
            start: new Date(flow.start).toISOString().replace(/T.*$/, ''),
            end: addDays(flow.start, flow.days),
            color: flow.publish ? '#2e7d32' : eventColor,
            publish: flow.publish,
          }));

          dispatch({
            type: DRAWFLOW_ACTIONS.HANDLE_CALENDAR_CHALLENGES,
            field: 'calendarChallenges',
            payload: flows,
          });
          dispatch({
            type: DRAWFLOW_ACTIONS.LOADING,
            payload: false,
          });
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  }, [token, dispatch]);

  useEffect(() => {
    getCalendarColorSettings();
    getChallenges();
  }, []);

  const ChallengeTitleTextField = ({ value }) => (
    <TextField
      label="Challenge Title"
      id="challengeTitle"
      type="text"
      name="title"
      placeholder={value ?? ''}
    />
  );

  const ChallengeNumberOfDays = ({ selectedDate }) => (
    <TextField
      type="number"
      min={1}
      label="End Date"
      id="challengeNumberOfDays"
    />
  );

  const handleEventDrop = (eventInfo) => {
    swal
      .fire({
        title: 'Are you sure of Change?',
        // text: 'Once deleted, you will not be able to recover this Flow!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No, return',
        confirmButtonText: 'Yes, Change!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-active-light',
        },
      })
      .then(({ value, isDismissed }) => {
        if (value) {
          if (
            new Date(eventInfo.event.start).setHours(0, 0, 0, 0) >=
            new Date().setHours(0, 0, 0, 0)
          ) {
            const body = {
              start: eventInfo.event.startStr,
            };
            const response = API.Patch(
              `/challenge/${eventInfo.event.id}`,
              body,
              token
            );
            toast.promise(response, {
              loading: 'Loading...',
              success: (data) => {
                getChallenges();
                return 'Edit Successful';
              },
              error: ({ response }) => {
                switch (response.status) {
                  case 400:
                    return response.data.message;
                  default:
                    return 'Error Editing';
                }
              },
            });
          } else {
            eventInfo.revert();
            toast.error("Event can't start days less than today");
          }
        }
        if (isDismissed) {
          eventInfo.revert();
          toast('No Change was done!', { icon: <InfoIcon color="info" /> });
        }
      });
  };

  const handleDateSelect = (selectInfo) => {
    if (
      new Date(selectInfo.start).setHours(0, 0, 0, 0) >=
      new Date().setHours(0, 0, 0, 0)
    ) {
      let calendarApi = selectInfo.view.calendar;

      calendarApi.unselect(); // clear date selection

      swal
        .fire({
          html: (
            <div>
              <Stack spacing={2}>
                <Typography>Create new Challenge?</Typography>
                <ChallengeTitleTextField />
                <ChallengeNumberOfDays />
              </Stack>
            </div>
          ),
          icon: 'info',
          backdrop: true,
          showCancelButton: true,
          cancelButtonText: 'No, return',
          confirmButtonText: 'Yes, create it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-active-light',
          },
          preConfirm: () => {
            const title = document.querySelector('input[name="title"]').value;
            const numberOfDays = document.querySelector(
              '#challengeNumberOfDays'
            ).value;

            if (!title.match(/[a-z A-Z]/gm))
              return swal.showValidationMessage('Title is Required');
            if (!numberOfDays && isNaN(parseInt(numberOfDays)))
              return swal.showValidationMessage('Number of days is Required');
            if (parseInt(numberOfDays) < 1)
              return swal.showValidationMessage(
                'Number of days should at least start at 1 day'
              );
            // if (
            //   endDate &&
            //   new Date(endDate).setHours(0, 0, 0, 0) <
            //     new Date(selectInfo.start).setHours(0, 0, 0, 0)
            // )
            //   return swal.showValidationMessage(
            //     "End Date can't be less than start date"
            //   );
          },
        })
        .then(({ value, isDismissed }) => {
          if (value) {
            const title = document.querySelector('input[name="title"]').value;
            const numberOfDays = document.querySelector(
              '#challengeNumberOfDays'
            ).value;

            if (title && numberOfDays) {
              calendarApi.addEvent({
                title: title,
                start: selectInfo.start,
                end: addDays(selectInfo.startStr, numberOfDays),
                days: numberOfDays,
                allDay: selectInfo.allDay,
              });
            } else {
            }
            calendarApi.unselect();
          }
        });
    }
  };

  const handleOnAddChallenge = ({ event }) => {
    // const oneDay = 24 * 60 * 60 * 1000;
    // const days =
    //   Math.round(
    //     Math.abs(
    //       new Date(event.end).getTime() - new Date(event.start).getTime()
    //     ) / oneDay
    //   ) + 1;

    const body = {
      name: event.title,
      days: event.extendedProps.days,
      start: event.startStr,
      data: {},
    };
    const drawflow = getChallengeFlow(body);

    body.data = drawflow;

    const response = API.Post(`/challenge`, body, token);

    toast.promise(response, {
      loading: 'Creating new Challenge...',
      success: ({ data }) => {
        navigate(`/apps/fb_hacker_challenge/flows/${data.challenge._id}`, {
          state: {
            platform: 'fb_hacker_challenge',
            id: data.challenge._id,
          },
        });
        return 'Challenge Created';
      },
      error: ({ response }) => {
        switch (response.status) {
          case 400:
            event.remove();
            return response.data.message;
          default:
            event.remove();
            return 'Error creating Flow';
        }
      },
    });
  };

  const handleEventClick = (clickInfo) => {
    const event = clickInfo.event;

    setMenuAnchorEl(clickInfo.el);
    setEventClicked(event);
  };

  const handlePublish = () => {
    if (eventClicked && eventClicked.id) {
      const status = !eventClicked.extendedProps.publish;
      const body = { publish: status };

      try {
        const response = API.Patch(
          `/challenge/${eventClicked.id}`,
          body,
          token
        );

        toast.promise(response, {
          loading: `${status ? 'Publishing' : 'Unpublishing'}`,
          success: (data) => {
            getChallenges();
            return `Challenge has been ${status ? 'Published' : 'Unpublished'}`;
          },
          error: `Error ${status ? 'Publishing' : 'Unpublishing'}`,
        });
      } catch (error) {
        if (error.response.status === 500)
          return toast.error('Something went wrong');
      }
    }
  };

  const handleDuplicate = () => {
    if (eventClicked && eventClicked.id) {
      const body = {
        id: eventClicked.id,
      };
      const response = API.Post('challenge/duplicate', body, token);
      toast.promise(response, {
        loading: 'Duplicating',
        success: (data) => {
          getChallenges();
          return 'Challenge has been Duplicated';
        },
        error: ({ response }) => {
          switch (response.status) {
            case 400:
              break;
            default:
              return 'Error Duplicating';
          }
        },
      });
    }
  };

  const handleEdit = () => {
    if (eventClicked && eventClicked.id) {
      return navigate(`/apps/fb_hacker_challenge/flows/${eventClicked.id}`, {
        state: {
          platform: 'fb_hacker_challenge',
          id: eventClicked.id,
        },
      });
    }
  };

  const handleRename = () => {
    if (eventClicked && eventClicked.id) {
      swal
        .fire({
          html: (
            <div>
              <Stack spacing={2}>
                <Typography>Rename Challenge?</Typography>
                <ChallengeTitleTextField value={eventClicked.title} />
              </Stack>
            </div>
          ),
          icon: 'info',
          backdrop: true,
          showCancelButton: true,
          cancelButtonText: 'No, return',
          confirmButtonText: 'Yes, Rename it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-active-light',
          },
          preConfirm: () => {
            const title = document.querySelector('input[name="title"]').value;

            if (!title.match(/[a-z A-Z]/gm))
              return swal.showValidationMessage('Title is Required');
          },
        })
        .then(({ value, isDismissed }) => {
          if (value) {
            const title = document.querySelector('input[name="title"]').value;
            const body = {
              name: title,
            };
            const response = API.Patch(
              `/challenge/${eventClicked.id}`,
              body,
              token
            );
            toast.promise(response, {
              loading: 'Loading...',
              success: (data) => {
                getChallenges();
                return 'Edit Successful';
              },
              error: ({ response }) => {
                switch (response.status) {
                  case 400:
                    return response.data.message;
                  default:
                    return 'Error Editing';
                }
              },
            });
          }
        });
    }
  };

  const handleDelete = () => {
    if (eventClicked && eventClicked.id) {
      swal
        .fire({
          title: 'Are you sure?',
          text: 'Once deleted, you will not be able to recover this Flow!',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No, return',
          confirmButtonText: 'Yes, Delete!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-active-light',
          },
        })
        .then(({ value, isDismissed }) => {
          if (value) {
            const res = API.Delete(`challenge/${eventClicked.id}`, token);
            toast.promise(res, {
              loading: 'Deleting',
              success: (data) => {
                eventClicked.remove();
                return 'Challenge has been Deleted';
              },
              error: 'Error Deleting',
            });
          }
          if (isDismissed) {
            swal.fire({
              title: 'Your Flow is safe!',
              icon: 'info',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            });
          }
        });
    }
  };

  const handleColorPalette = () =>
    swal.fire({
      title: 'Calendar Color Palette',
      html: (
        <div>
          <ColorPaletteComponent token={token} />
        </div>
      ),
      icon: <PaletteIcon />,
      showCloseButton: true,
      showConfirmButton: false,
      backdrop: false,
      position: 'top-right',
      showClass: {
        popup: 'animate__animated animate__fadeInRight',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutRight',
      },
    });

  return (
    <>
      <div>
        {state.loading ? (
          <Box
            sx={{
              height: '80vh',
              width: '80vw',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div style={{ height: '100vh', overflowY: 'scroll' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                bottom: 18,
                right: 25,
                zIndex: 34,
                background: '#f9f9f9',
                borderRadius: '50px',
                boxShadow:
                  'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
              }}
            >
              <IconButton
                onClick={handleColorPalette}
                color="secondary"
                size="large"
              >
                <PaletteIcon />
              </IconButton>
            </div>
            <div className="demo-app-main">
              <div className="background-image"></div>
              <FullCalendar
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  momentPlugin,
                ]}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                ref={calendarRef}
                themeSystem="Literia"
                initialView="dayGridMonth"
                initialDate={new Date()}
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                events={state.calendarChallenges}
                eventColor={eventColor}
                select={handleDateSelect}
                eventClick={handleEventClick}
                eventAdd={handleOnAddChallenge}
                eventMouseEnter={handlePopoverOpen}
                eventMouseLeave={handlePopoverClose}
                eventDrop={handleEventDrop}
                // datesSet={getChallenges}
                // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                /* you can update a remote database when these fire:
                eventChange={function(){}}
                eventRemove={function(){}}
                */
              />
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                  zIndex: '99999',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                PaperProps={{
                  style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    borderRadius: 0,
                  },
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    mt: '10px',
                    '&::before': {
                      backgroundColor: 'rgba(0,0,0,1)',
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      width: 12,
                      height: 12,
                      top: -6,
                      transform: 'rotate(45deg)',
                      left: 'calc(50% - 6px)',
                    },
                  }}
                />
                <Paper
                  sx={{
                    backgroundColor: 'rgba(0,0,0,1)',
                    color: 'white',
                    minWidth: '50px',
                  }}
                >
                  <Typography sx={{ p: 1 }}>{hoverPopOverText}</Typography>
                </Paper>
              </Popover>
              <Menu
                anchorEl={menuAnchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              >
                <MenuItem onClick={handleEdit}>
                  <ListItemIcon>
                    <EditAttributesIcon fontSize="small" />
                  </ListItemIcon>
                  Edit Challenge
                </MenuItem>
                <MenuItem onClick={handlePublish}>
                  <ListItemIcon>
                    {eventClicked && !eventClicked.extendedProps.publish ? (
                      <PublishIcon fontSize="small" />
                    ) : (
                      <UnpublishedIcon fontSize="small" />
                    )}
                  </ListItemIcon>
                  {eventClicked && !eventClicked.extendedProps.publish
                    ? 'Publish'
                    : 'Unpublish'}
                </MenuItem>
                <MenuItem onClick={handleRename}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  Rename Challenge
                </MenuItem>
                <MenuItem onClick={handleDuplicate}>
                  <ListItemIcon>
                    <ContentCopyIcon fontSize="small" />
                  </ListItemIcon>
                  Duplicate
                </MenuItem>

                <Divider />
                <MenuItem onClick={handleDelete} sx={{ color: '#d32f2f' }}>
                  <ListItemIcon>
                    <DeleteOutlineIcon fontSize="small" color="error" />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </Menu>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChallengesPage;
