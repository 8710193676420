import React from 'react';

const BaseStyleComponent = ({ children }) => {
  return (
    <div
      style={{
        marginTop: '40px',
        padding: '40px',
        height: '100%',
      }}
    >
      {children}
    </div>
  );
};

export default BaseStyleComponent;
