import { Card, Stack, Typography } from '@mui/material'
import React from 'react'
import { MessageLogo } from '../../utils/logos'

function BlocksVaVicky({drag}) {
  return (
    <div>
       <Card
                className="drag-item"
                draggable="true"
                onDragStart={drag}
                data-node="vatext"
                sx={{ marginBottom: '13px' }}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <img
                    src={MessageLogo}
                    style={{
                      width: 25,
                      height: 25,
                      padding: '10px',
                      color: '#6e6d6d',
                    }}
                    alt="messageLogo"
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      color: '#6e6d6d',
                      fontSize: '1rem',
                      fontWeight: '400',
                    }}
                  >
                    Send Message
                  </Typography>
                </Stack>
              </Card> 
    </div>
  )
}

export default BlocksVaVicky